import type { FC, ReactElement } from 'react'
import { ResearchCenter } from '../../GraphQL/graphql'
import { useProfile } from '../../Hooks/useProfile'

const logos: Record<ResearchCenter, ReactElement> = {
  [ResearchCenter.Lmu]: (
    <img
      className="inline-block h-12"
      src="https://cdn0.scrvt.com/4d3e519fe5939342b95c7312343779ef/c736dfe931dfb22d/0e6f79ca70bd/Logo-klinikum.svg"
      alt="LMU Klinikum"
    />
  ),
  [ResearchCenter.Ocm]: (
    <img
      className="inline-block h-12"
      src="https://www.ocm-muenchen.de/typo3conf/ext/pxocm/Resources/Public/Images/logo.svg"
      alt="OCM München"
    />
  ),
  [ResearchCenter.Weilheim]: (
    <img
      className="inline-block h-14"
      src="https://www.meinkrankenhaus2030.de/hubfs/khws_logo_rgb.svg"
      alt="Klinik Weilheim"
    />
  ),
}

export const ResearchNav: FC = () => {
  const { profile } = useProfile()

  if (!profile?.isResearch || !profile?.researchCenter) return null

  return (
    <nav className="flex items-center px-4 py-3 bg-white">
      <div className="flex flex-col items-baseline md:flex-row">
        {logos[profile.researchCenter]}

        <span className="mt-3 text-sm md:ml-12 md:mt-0">unterstützt durch</span>
        <img
          className="inline-block h-5 md:ml-2"
          src="https://docrobin.net/wp-content/uploads/DocRobin-Logo-Website.png"
          alt="DocRobin"
        />
      </div>
      <div className="flex-auto" />
      <div className="flex flex-col flex-none text-sm text-right">
        <div>Teilnehmer ID</div>
        <div className="text-lg font-bold text-right text-[--color-1]">
          {profile.researchParticipantId}
        </div>
      </div>
      {/* <div className="grow" />
      <div className="flex items-center justify-center">
        <NavLink className="dro-button dro-button--primary" to="/logout">
          Studie abschließen
        </NavLink>
      </div> */}
    </nav>
  )
}

import cn from 'classnames'
import _ from 'lodash'
import type { HTMLAttributes } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ControlRepeat } from '../../assets/control_repeat.svg'
import {
  getVideoUrl,
  getVideoUrlForConversation,
} from '../../Helper/ConversationHelper'
import { getLanguageCode } from '../../Helper/LanguageHelper'

interface Props {
  videoIdentifier?: string | null
  isLoading?: boolean
  className?: HTMLAttributes<HTMLDivElement>['className']
  videoPath?: string
}

// TODO
export const ChatVideo: React.FC<Props> = ({
  videoIdentifier,
  className,
  isLoading = false,
  videoPath = 'chat',
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const video = videoRef.current
  const { t } = useTranslation()

  const [videoExists, setVideoExists] = useState(false)
  const [videoEnded, setVideoEnded] = useState(false)
  const [videoPaused, setVideoPaused] = useState(true)

  const isFillerShown =
    isLoading || !videoIdentifier || !videoExists || videoEnded

  const playVideo = useCallback(() => video?.play(), [video])

  useEffect(() => {
    setVideoExists(false)
    setVideoEnded(false)
    setVideoPaused(true)
    video?.load()

    return () => video?.pause()
  }, [videoIdentifier, video])

  return (
    <div
      className={cn('relative leading-[0px] bg-white rounded-4xl', className)}
    >
      {videoIdentifier && (
        <div className={cn({ hidden: isFillerShown })}>
          <video
            className="h-full max-h-[70vh] max-md:rounded-lg rounded-4xl w-[100vw] mb-3"
            ref={videoRef}
            autoPlay
            playsInline
            src={getVideoUrlForConversation(
              videoIdentifier,
              getLanguageCode(),
              videoPath
            )}
            poster={`${process.env.REACT_APP_S3_VIDEOS_CHAT}/${videoPath}/preview.jpg`}
            onClick={() => (video?.paused ? video?.play() : video?.pause())}
            onCanPlay={() => setVideoExists(true)}
            onError={() => setVideoExists(false)}
            onPlay={() => {
              setVideoPaused(false)
              setVideoEnded(false)
            }}
            onPause={() => _.defer(() => setVideoPaused(true))}
            onEnded={() => setVideoEnded(true)}
          />
          <div
            className={cn(
              'absolute cursor-pointer p-3 top-3 left-3 right-3 bottom-3 flex items-center justify-center',
              {
                hidden: !videoPaused,
              }
            )}
            onClick={playVideo}
          >
            <i className="old-color-3 text-[8em] fa fa-play-circle" />
          </div>
        </div>
      )}
      <div className="relative">
        <video
          className={cn(
            'h-full max-h-[70vh] max-md:rounded-lg rounded-4xl w-[100vw] mb-3',
            {
              hidden: !isFillerShown,
            }
          )}
          autoPlay
          playsInline
          muted
          loop
          src={getVideoUrl('pause', '', videoPath)}
          poster={`${process.env.REACT_APP_S3_VIDEOS_CHAT}/${videoPath}/preview.jpg`}
        />
        <ControlRepeat
          className={cn(
            'absolute top-4 right-4 cursor-pointer bg-red rounded w-12 h-12',
            {
              invisible: !videoEnded,
            }
          )}
          onClick={playVideo}
          alt={t('Repeat video')}
        />
      </div>
      {/* <div className="absolute cursor-pointer p-3  max-[360px]:top-[40vw] max-[375px]:top-[30vw] max-[393px]:top-[45vw] max-[400px]:top-[35vw] max-[420px]:top-[45vw] max-[820px]:top-[20vw] max-[920px]:top-[20vw] top-[20px] right-[340px]"></div> */}
    </div>
  )
}

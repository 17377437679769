import classNames from 'classnames'
import type { ReactNode } from 'react'
import './Table.scss'

interface Props {
  hasHeaderColumn?: boolean
  header?: ReactNode
  children: ReactNode
  footer?: ReactNode
  className?: string
}
export const Table: React.FC<Props> = ({
  header,
  children,
  footer,
  hasHeaderColumn = false,
  className,
}) => {
  /**
   * This table is just a wrapper to apply styles.
   */
  return (
    <table
      className={classNames(
        'Table',
        {
          'Table--header-column': hasHeaderColumn,
        },
        className
      )}
    >
      {header && <thead>{header}</thead>}
      <tbody>{children}</tbody>
      {footer && <tfoot>{footer}</tfoot>}
    </table>
  )
}

import classNames from 'classnames'
import type { FC, HTMLAttributes } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ProfileError,
  type UpdateEmailMutationVariables,
} from '../../GraphQL/graphql'
import { authErrorToMessage, emailRegex } from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { useProfile } from '../../Hooks/useProfile'
import { AlertMessage } from '../AlertMessage/AlertMessage'

export const ProfileUpdateEmail: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...divArgs
}) => {
  const { t } = useTranslation()

  const [editEmail, setEditEmail] = useState(false)

  const {
    profile,
    mutations: {
      updateEmail: [updateEmail],
    },
  } = useProfile()

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<UpdateEmailMutationVariables>({
    defaultValues: useMemo(() => {
      return { email: profile?.email ?? undefined }
    }, [profile?.email]),
  })

  useEffect(() => {
    reset({ email: profile?.email ?? undefined })
  }, [profile?.email, reset])

  const onSubmit = handleSubmit(async (variables) => {
    await updateEmail({
      variables,
      onCompleted: ({ updateEmail }) => {
        if (updateEmail.authError) {
          setError('root', {
            message: authErrorToMessage(updateEmail.authError, t),
          })
        } else if (updateEmail.profileErrors) {
          const errors: string[] = []
          updateEmail.profileErrors.forEach((error) => {
            switch (error) {
              case ProfileError.InvalidEmailFormat:
                setError('email', {
                  message: profileErrorToMessage(error, t),
                })
                break
              default:
                errors.push(profileErrorToMessage(error, t))
                break
            }
            if (errors.length) {
              setError('root', {
                message: errors.join(' | '),
              })
            }
          })
        } else {
          setEditEmail(false)
          reset()
        }
      },
      onError: () =>
        setError('root', {
          message: t(
            'Saving not possible. If this error occurs repeatedly, please contact our support.'
          ),
        }),
    })
  })

  return (
    <div {...divArgs}>
      <h3>E-Mail</h3>

      {errors.root?.message && (
        <AlertMessage
          type="warning"
          message={errors.root.message}
          onClose={() => clearErrors('root')}
        />
      )}

      {isSubmitSuccessful && profile?.pendingEmail && !isDirty && (
        <AlertMessage
          type="success"
          message={t(
            'E-mail successfully saved. You have been sent a confirmation link to {{email}} that you can use to activate it.',
            { email: profile.pendingEmail }
          )}
          onClose={() => reset()}
        />
      )}

      <form onSubmit={onSubmit}>
        <fieldset className="my-[13px]">
          <label htmlFor="email" className="invisible">
            {t('Email')}
          </label>
          <input
            className={classNames(
              editEmail ? 'active_input' : 'input',
              errors.email && '!border-red-600 !border-2'
            )}
            disabled={isSubmitting || !editEmail}
            type="text"
            placeholder={t('Email')}
            {...register('email', {
              required: t('This field cannot be empty.'),
              pattern: {
                value: emailRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidEmailFormat,
                  t
                ),
              },
            })}
          />
          {errors.email?.message && (
            <label
              htmlFor="birthdate"
              className="text-red-600 flex justify-end"
            >
              {errors.email?.message}
            </label>
          )}

          {editEmail && (
            <button
              type="submit"
              className="mr-4 button-primary button-m"
              disabled={isSubmitting || !isDirty}
            >
              {isSubmitting ? 'Speichert...' : 'E-Mail ändern'}
            </button>
          )}

          <button
            type="button"
            className={classNames(
              'mt-3 button-m',
              editEmail ? 'button-secondary' : 'button-primary'
            )}
            onClick={() => {
              reset()
              setEditEmail(!editEmail)
            }}
          >
            {editEmail ? 'Abbrechen' : 'Bearbeiten'}
          </button>
        </fieldset>
      </form>
    </div>
  )
}

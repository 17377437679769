import { type ComponentProps, forwardRef } from 'react'

export const notificationMethods = [
  {
    id: 'email',
    title: 'Frau',
  },
  {
    id: 'email2',
    title: 'Herr',
  },
]

interface RadioGroupProps {
  options: {
    value: string
    title: string
  }[]
  name: string
  onChange: ComponentProps<'input'>['onChange']
  onBlur: ComponentProps<'input'>['onBlur']
  className?: string
  disabled?: boolean
}

export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  function RadioGroupInner({ options, className, ...inputProps }, ref) {
    return (
      <div className={className}>
        <fieldset className="mt-4">
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {options.map((option) => (
              <div key={option.value} className="flex items-center">
                <input
                  id={option.value}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-dro-green focus:ring-dro-green cursor-pointer"
                  value={option.value}
                  ref={ref}
                  {...inputProps}
                />
                <label
                  htmlFor={option.value}
                  className="ml-3 block leading-6 cursor-pointer"
                >
                  {option.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    )
  }
)

import { PaperAirplaneIcon } from '@heroicons/react/20/solid'
import type { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  onClick: MouseEventHandler
  disabled?: boolean
}

export const SendInputButton: React.FC<Props> = ({ onClick, disabled }) => {
  const { t } = useTranslation()

  return (
    <button
      className="old-button-circular pt-1.5"
      disabled={disabled}
      onClick={onClick}
    >
      <PaperAirplaneIcon className="w-6 h-6 mx-auto -rotate-45" />
      <span className="invisible">{t('Send')}</span>
    </button>
  )
}

import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLink } from '../../Components/HeaderLink/HeaderLink'
import { LanguageSelector } from '../../Components/LanguageSelector/LanguageSelector'
import { useProfile } from '../../Hooks/useProfile'

export const DevelopmentNav: FC = () => {
  const { t } = useTranslation()
  const { profile } = useProfile()

  return (
    <header
      style={
        process.env.NODE_ENV !== 'development' || process.env.REACT_APP_NAV
          ? { display: 'none' }
          : {}
      }
    >
      <nav
        aria-label={t('Primary navigation')}
        className="overflow-x h-14 sticky top-0 w-full [&>.active]:bg-[#438f04] [&>.active]:text-[#ffffff]"
      >
        {!profile?.isComplete && (
          <HeaderLink to="/login">{t('Login')}</HeaderLink>
        )}
        <HeaderLink to="/chat">{t('Chat')}</HeaderLink>
        <HeaderLink to="/dialog">{t('Dialog')}</HeaderLink>
        {profile?.isComplete && (
          <>
            <HeaderLink to="/files">{t('Files')}</HeaderLink>
            <HeaderLink to="/reports">{t('Reports')}</HeaderLink>
            <HeaderLink to="/orders">{t('Payments')}</HeaderLink>
          </>
        )}
        {profile?.isComplete && (
          <HeaderLink to="/profile">{t('Profile')}</HeaderLink>
        )}
        <div style={{ float: 'right' }} className="hover:h-full">
          {profile?.isComplete && (
            <HeaderLink to="/logout">{t('Logout')}</HeaderLink>
          )}
          <LanguageSelector />
        </div>
      </nav>
    </header>
  )
}

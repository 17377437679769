import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  linkTo?: string
  className?: string
  children: ReactNode
}

export const Card: React.FC<Props> = ({ children, linkTo, className }) => {
  /**
   * Please note that linking the whole card has some accessibility implications.
   * If you wish to link the card to a page or an action, please add a child that behaves accordingly.
   * The Card components pretends to be non-interactive (non-focusable, no label).
   */
  const navigate = useNavigate()

  const clickHandler = () => {
    if (!linkTo) {
      return
    }
    navigate(linkTo)
  }

  return (
    <section
      tabIndex={-1}
      className={classNames(
        'mt-6 p-6 rounded-xl overflow-hidden first:mt-0 last:mb-0 last-of-type:mb-6 shadow-lg',
        { 'cursor-pointer': !!linkTo },
        className
      )}
      onClick={clickHandler}
    >
      {children}
    </section>
  )
}

export const ResearchEndScreen = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="my-12 bg-white shadow rounded-xl">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h1 className="text-3xl font-bold tracking-tight sm:text-5xl">
              Vielen Dank für die Teilnahme an der Studie!
            </h1>
            <p className="max-w-xl mx-auto mt-6 text-2xl leading-8 text-gray-600">
              Bitte wenden Sie sich an den Studienleiter
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

import type { FC } from 'react'

type Props = {
  progress?: number
}
export const ConversationProgressBar: FC<Props> = ({ progress }) => {
  if (progress === undefined || !process.env.REACT_APP_IS_RESEARCH_INSTANCE) {
    return null
  }

  return (
    <div className="w-full">
      <div
        className="h-1 bg-old-color-1"
        style={{
          width: `${progress * 100}%`,
        }}
      />
    </div>
  )
}

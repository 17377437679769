import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import { MdRenderer } from '../../Components/MdRenderer/MdRenderer'
import { Shell } from '../../Components/Shell/Shell'
import { useReportPreview } from '../../Hooks/useReportPreview'
import { Alert } from '../../Modules/Alert/Alert'
import { Loading } from '../../Modules/Loading/Loading'

type ReportPreviewParams = {
  sessionId?: string
}

export const ReportPreview: React.FC = () => {
  // const { t } = useTranslation()
  const { sessionId } = useParams<ReportPreviewParams>()
  const { report, reportLoading, reportError } = useReportPreview(sessionId)

  if (reportLoading) {
    return (
      <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
        <Loading />
      </Shell>
    )
  }

  if (reportError || !report) {
    return (
      <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
        <Alert message={t('Could not load report.')} />
      </Shell>
    )
  }

  return (
    <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
      <MdRenderer mdText={report} styling />
    </Shell>
  )
}

import cn from 'classnames'
import React from 'react'
import type { SessionChatFragment } from '../../GraphQL/graphql'
import type { UseSessionStateType } from '../../Hooks/useActiveSession'
import { ChatVideo } from '../ChatVideo/ChatVideo'
import { DeleteChatButton } from '../DeleteChatButton/DeleteChatButton'
import { MdRenderer } from '../MdRenderer/MdRenderer'

interface ChatProps {
  sessionId?: string
  messages?: SessionChatFragment['messages']
  lastUserMessage?: SessionChatFragment['messages'][0]
  withVideo?: boolean
  readOnly?: boolean
  useSessionState?: UseSessionStateType
  videoPath?: string
}

const MESSAGE_STYLES =
  'md:max-w-[60%] text-lg md:px-3 md:py-2 rounded-[18px] max-w-[90%] p-2'

export const Chat: React.FC<ChatProps> = ({
  sessionId,
  messages,
  lastUserMessage,
  useSessionState,
  withVideo,
  readOnly,
  videoPath,
}) => {
  const [sesstionState, _] = useSessionState ?? []
  return (
    <section className="flex flex-col gap-4 mb-4 p-2 md:p-8 rounded-[18px]">
      {messages?.map((message) => (
        <React.Fragment key={message.id}>
          {message.botText && (
            <>
              {withVideo && messages.at(-1) === message && (
                <ChatVideo
                  className={cn('bd-dro-midgray top-12 sticky', MESSAGE_STYLES)}
                  // TODO: change to `videoIdentifier`
                  videoIdentifier={message.id}
                  videoPath={videoPath}
                />
              )}

              <div
                className={cn(
                  MESSAGE_STYLES,
                  'self-start bg-dro-midgray text-dro-blue'
                )}
              >
                <MdRenderer mdText={message.botText} styling={false} />
              </div>
            </>
          )}
          {message.userText !== null && (
            <div
              className={cn(
                MESSAGE_STYLES,
                'self-end text-dro-darkgreen bg-dro-lightgreen'
              )}
            >
              <p>{message.userText}</p>
            </div>
          )}
          {message.infoText !== null && (
            <div className="self-center p-2 text-dro-blue">
              <p>{message.infoText}</p>
            </div>
          )}

          {!readOnly &&
            sessionId &&
            !sesstionState?.loading &&
            message.id === lastUserMessage?.id && (
              <div className="text-end -mt-4">
                <DeleteChatButton
                  className="old-link text-xs"
                  sessionId={sessionId}
                  lastElementId={messages?.at(-1)?.treeElementId}
                  useSessionState={useSessionState}
                />
              </div>
            )}
        </React.Fragment>
      ))}
    </section>
  )
}

import { CheckIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { AlertMessage } from '../../Components/AlertMessage/AlertMessage'
import type { Circle } from '../../Components/Layout/Layout'
import { Shell } from '../../Components/Shell/Shell'
import { AuthContext } from '../../Contexts/AuthContext'

import type { SendSecondAuthFactorMutationVariables } from '../../GraphQL/graphql'
import {
  AuthError,
  type LoginUserMutationVariables,
  type SendPasswordResetMutationVariables,
} from '../../GraphQL/graphql'
import { authErrorToMessage, factorRegex } from '../../Helper/AuthHelper'
import { useProfile } from '../../Hooks/useProfile'

enum LoginState {
  EnterEmailPassword,
  VerifyFactor,
  ResendFactor,
  ResetPassword,
}

const circles: Array<Circle> = [
  {
    color: '#54c4bd21',
    top: 1,
    left: 3,
  },
  {
    color: '#61c45421',
    bottom: 5,
    right: 5,
  },
]

export const Login: React.FC = () => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)

  const [state, setState] = useState<LoginState>(LoginState.EnterEmailPassword)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const nextUrl = searchParams.get('next')
  const transfer = searchParams.has('transfer')

  const [passwordResetMail, setPasswordResetMail] = useState('')

  const { mutations } = useProfile()
  const [loginUser] = mutations.loginUser
  const [sendPasswordReset] = mutations.sendPasswordReset
  const [sendSecondAuthFactor] = mutations.sendSecondAuthFactor

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    setFocus,
    reset,
    formState: { errors, isDirty, isSubmitting },
    watch,
    setValue,
  } = useForm<
    LoginUserMutationVariables &
      SendSecondAuthFactorMutationVariables &
      SendPasswordResetMutationVariables
  >({
    defaultValues: {
      email: undefined,
      password: undefined,
      transferSession: transfer ?? false,
      factor: undefined,
    },
  })

  const transferSession = watch('transferSession')

  useEffect(() => {
    if (state === LoginState.VerifyFactor) {
      setFocus('factor')
    }
  }, [state, setFocus])

  const onSubmit = handleSubmit(
    async ({ email, password, transferSession, factor }) => {
      if (
        state === LoginState.EnterEmailPassword ||
        state === LoginState.ResendFactor
      ) {
        await sendSecondAuthFactor({
          variables: { email, password },
          onCompleted: ({ sendSecondAuthFactor }) => {
            if (sendSecondAuthFactor.error) {
              setError('root', {
                message: authErrorToMessage(sendSecondAuthFactor.error, t),
              })
              setState(LoginState.ResetPassword)
            } else if (sendSecondAuthFactor.success) {
              setState(LoginState.VerifyFactor)
            }
          },
          onError: () =>
            setError('root', {
              message: t(
                'An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
              ),
            }),
        })
      } else if (state === LoginState.ResetPassword) {
        await sendPasswordReset({
          variables: { email },
          onCompleted: ({ sendPasswordReset }) => {
            if (sendPasswordReset) {
              setPasswordResetMail(email)
              setState(LoginState.EnterEmailPassword)
            } else {
              setError('root', {
                message: t(
                  'An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
                ),
              })
            }
          },
          onError: () =>
            setError('root', {
              message: t(
                'An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
              ),
            }),
        })
      } else if (state === LoginState.VerifyFactor) {
        await loginUser({
          variables: { email, password, factor, transferSession },
          onCompleted: async ({ loginUser }) => {
            if (loginUser.error) {
              setError('root', {
                message: authErrorToMessage(loginUser.error, t),
              })
            } else if (loginUser.accessToken) {
              await updateAuth(loginUser.accessToken)
              navigate(nextUrl ?? '/chat')
            }
          },
          onError: () =>
            setError('root', {
              message: t(
                'An error has occurred. If this error occurs repeatedly, please get in touch with our support team.'
              ),
            }),
        })
      }
    }
  )

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={circles}>
      <div className="flex flex-col min-[650px]:w-[555px] mx-auto">
        <h1 className="color-3 text-[64px]/[72px] text-center">{t('Login')}</h1>
        <div className="flex justify-center mt-5 mb-6">
          <p className="mr-4 text-lg color-3 inline-block">
            {t('Not yet registered?')}
          </p>
          <Link
            className="inline-block text-lg link"
            to={`/register?${searchParams}`}
          >
            {t('Sign up')}
          </Link>
        </div>
        {passwordResetMail && (
          <div className="mb-10">
            <AlertMessage
              type="success"
              message={t(
                'Password reset instructions has been sent to {{email}}.',
                { email: passwordResetMail }
              )}
              onClose={() => setPasswordResetMail('')}
            />
          </div>
        )}
        {errors.root?.message && (
          <div className="mb-10">
            <AlertMessage
              type="warning"
              message={errors.root.message}
              onClose={() => clearErrors('root')}
            />
          </div>
        )}
        <form onSubmit={onSubmit}>
          <div className="mb-6">
            <label
              htmlFor="email"
              className="color-3 text-lg cursor-pointer mb-2 inline-block"
            >
              {t('Email')}
            </label>
            <input
              className={classNames(
                'input',
                errors.email && '!border-red-600 !border-2'
              )}
              id="email"
              disabled={isSubmitting || state !== LoginState.EnterEmailPassword}
              type="text"
              placeholder={t('Email')}
              {...register('email', {
                required: t('This field cannot be empty.'),
              })}
            />
            {errors.email?.message && (
              <label htmlFor="email" className="text-red-600 flex justify-end">
                {errors.email?.message}
              </label>
            )}
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="color-3 text-lg cursor-pointer mb-2 inline-block"
            >
              {t('Password')}
            </label>
            <input
              className={classNames(
                'input',
                errors.password && '!border-red-600 !border-2'
              )}
              id="password"
              disabled={isSubmitting || state !== LoginState.EnterEmailPassword}
              type="password"
              placeholder={t('Password')}
              {...register('password', {
                required: t('This field cannot be empty.'),
              })}
            />
            {errors.password && (
              <label
                htmlFor="password"
                className="text-red-600 flex justify-end text-right"
              >
                {errors.password.message || t('This field cannot be empty.')}
              </label>
            )}
          </div>
          <div className="flex items-start mb-8">
            <label
              aria-checked={transferSession as boolean}
              className="relative flex items-start gap-3 px-5 py-3 text-gray-900 sm:py-2 sm:px-0 group aria-checked:sm:bg-transparent"
              onClick={() => {
                if (isSubmitting || state !== LoginState.EnterEmailPassword) {
                  return
                }

                setValue('transferSession', !transferSession)
              }}
            >
              <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
                <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
              </div>
              <div className="flex-1 min-w-0 text-left">
                {t(
                  'Transfer chat history and overwrite the one saved in your profile.'
                )}
              </div>
            </label>
          </div>

          {state === LoginState.VerifyFactor && (
            <>
              <label htmlFor="factor" className="invisible">
                {t('E-Mail Token')}
              </label>
              <input
                className={classNames(
                  'input',
                  errors.factor && '!border-red-600 !border-2'
                )}
                disabled={isSubmitting || state !== LoginState.VerifyFactor}
                type="number"
                inputMode="numeric"
                placeholder={t('E-Mail Token')}
                {...register('factor', {
                  validate: (token) => {
                    if (state === LoginState.VerifyFactor) {
                      return !factorRegex.test(token)
                        ? authErrorToMessage(AuthError.InvalidTwoFaToken, t)
                        : undefined
                    }
                  },
                })}
              />
              {errors.factor?.message && (
                <label
                  htmlFor="factor"
                  className="text-red-600 flex justify-end text-right"
                >
                  {errors.factor.message}
                </label>
              )}
            </>
          )}

          <button
            type="submit"
            className="button-primary button-l mt-4"
            disabled={isSubmitting || !isDirty}
          >
            {state === LoginState.EnterEmailPassword &&
              (isSubmitting
                ? t('Sending E-Mail...')
                : t('Send E-Mail confirmation'))}
            {state === LoginState.VerifyFactor &&
              (isSubmitting ? t('Loading…') : t('Login'))}
            {state === LoginState.ResetPassword &&
              (isSubmitting ? t('Loading…') : t('Reset password'))}
            {state === LoginState.ResendFactor &&
              (isSubmitting ? t('Loading…') : t('Resend E-Mail confirmation'))}
          </button>

          {state !== LoginState.EnterEmailPassword && (
            <button
              type="reset"
              className="button-secondary button-l mt-4 ml-4"
              disabled={isSubmitting || !isDirty}
              onClick={() => {
                setState(LoginState.EnterEmailPassword)
                reset()
              }}
            >
              {t('Back')}
            </button>
          )}
        </form>
      </div>
    </Shell>
  )
}

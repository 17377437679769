import {
  CheckCircleIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Button } from '../../Components/Button'
import { Spinner } from '../../Components/Spinner/Spinner'
import { usePaymentResult } from '../../Hooks/usePaymentResult'

export const PaymentResult = () => {
  const { data, loading, error } = usePaymentResult()
  const { t } = useTranslation()

  if (loading) {
    return <Spinner />
  }

  if (error || data?.getOrderPaymentIntent.error) {
    return (
      <div className="flex items-center justify-center flex-col">
        <div className="bg-yellow-400 rounded-full w-48 h-48 flex items-center justify-center mb-4">
          <QuestionMarkCircleIcon className="w-32 h-32 text-neutral-100" />
        </div>
        <p className="text-dro-blue font-bold text-4xl p-4 text-center">
          {t(
            'An error has occurred. Please get in touch with our support team.'
          )}
        </p>
      </div>
    )
  }

  if (data?.getOrderPaymentIntent.paymentIntent?.status === 'succeeded') {
    return (
      <div className="flex items-center justify-center flex-col">
        <div className="bg-green-400 rounded-full w-48 h-48 flex items-center justify-center mb-4">
          <CheckCircleIcon className="w-32 h-32 text-neutral-100" />
        </div>
        <p className="text-dro-blue font-bold text-4xl p-4 text-center">
          {t('Payment successful')}
        </p>
        <Button to="/reports">{t('Go to reports')}</Button>
      </div>
    )
  }

  if (data?.getOrderPaymentIntent.paymentIntent?.status === 'canceled') {
    return (
      <div className="flex items-center justify-center flex-col">
        <div className="bg-red-400 rounded-full w-48 h-48 flex items-center justify-center mb-4">
          <XMarkIcon className="w-32 h-32 text-neutral-100" />
        </div>
        <p className="text-dro-blue font-bold text-4xl p-4 text-center">
          {t('Payment cancelled')}
        </p>
      </div>
    )
  }

  if (
    data?.getOrderPaymentIntent.paymentIntent?.status ===
    'requires_payment_method'
  ) {
    return (
      <div className="flex items-center justify-center flex-col">
        <div className="bg-red-400 rounded-full w-48 h-48 flex items-center justify-center mb-4">
          <XMarkIcon className="w-32 h-32 text-neutral-100" />
        </div>
        <p className="text-dro-blue font-bold text-4xl p-4 text-center">
          {t('Payment failed!')}
        </p>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center flex-col">
      <div className="bg-gray-400 rounded-full w-48 h-48 flex items-center justify-center mb-4">
        <ClockIcon className="w-32 h-32 text-neutral-100" />
      </div>
      <p className="text-dro-blue font-bold text-4xl p-4 text-center">
        {t('Payment processing')}
      </p>
    </div>
  )
}

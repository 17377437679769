import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import type { FC, PropsWithChildren } from 'react'

type Props = {
  title?: string
  type: 'success' | 'warning' | 'danger'
  message?: string
  onClose?: () => void
}

export const AlertMessage: FC<PropsWithChildren<Props>> = ({
  message,
  onClose,
  title,
  type,
  children,
}) => {
  return (
    <div className="flex justify-center">
      <div className="relative border-2 rounded-md p-2 border-dro-blue border-solid">
        <div className="flex items-center space-x-2">
          {type === 'danger' && (
            <ExclamationTriangleIcon className="text-dro-red w-10" />
          )}
          {type === 'success' && (
            <CheckCircleIcon className="text-dro-darkgreen w-10" />
          )}
          {type === 'warning' && (
            <ExclamationTriangleIcon className="text-dro-blue w-10" />
          )}

          <div className="text-black text-lg">
            {title && <p className="text-lg font-bold text-black">{title}</p>}
            {children || message}
          </div>
        </div>
        {onClose && (
          <div
            onClick={onClose}
            className={classNames(
              'absolute -top-2 -right-2',
              'bg-dro-gray text-white',
              // You can add comments to your styling code.
              'w-5 h-5',
              'flex items-center justify-center',
              'rounded-full cursor-pointer'
            )}
          >
            <XCircleIcon className="w-10" />
          </div>
        )}
      </div>
    </div>
    // <div className="flex justify-center">
    //   <div className="flex items-center space-x-2 border-2 rounded-lg p-2 border-dro-gray border-solid">
    //     <XCircleIcon
    //       className="right-0 top-0 h-4 w-4 cursor-pointer text-dro-gray"
    //       onClick={onClose}
    //     />
    //     <ExclamationTriangleIcon className="text-dro-red w-10" />
    //     <div className="text-black text-lg">
    //       {title && <p className="text-md">{title}</p>}
    //       {children || message}
    //     </div>
    //   </div>
    // </div>
  )
}

import { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrderInvoice } from '../../Hooks/useOrderInvoice'
import type { ButtonProps } from '../Button'
import { Button } from '../Button'

type DownloadInvoiceProps = ButtonProps & {
  orderId: string
}

export const DownloadInvoiceButton: FC<DownloadInvoiceProps> = ({
  orderId,
  children,
  ...props
}) => {
  const { getInvoice, invoiceLoading } = useOrderInvoice()
  const { t } = useTranslation()

  const onClick = async () => {
    const invoice = await getInvoice({ variables: { orderId } })

    if (invoice.data?.getOrderInvoice.error) {
      alert('Something went wrong, please try again later')
    }

    if (invoice.data?.getOrderInvoice.uri) {
      const anchor = document.createElement('a')
      anchor.href = invoice.data?.getOrderInvoice.uri
      anchor.download = `order-${orderId}.pdf`
      anchor.style.display = 'none'
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    }
  }

  return (
    <Button onClick={onClick} disabled={invoiceLoading} {...props}>
      {invoiceLoading ? t('Loading…') : children ?? t('Download invoice')}
    </Button>
  )
}

import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { identity, pickBy } from 'lodash'
import {
  GetAccessRefreshTokenDocument,
  GetAccessRefreshTokenQueryVariables,
  ReauthenticateSocketDocument,
} from '../GraphQL/graphql'
import { authorizationHeader, getAccessToken } from './AuthHelper'
import { getLanguageCode } from './LanguageHelper'
import { createSubscriptionClient } from './WebSocketHelper'

const SELECTED_LANGUAGE_PARAM_NAME = 'Selected-Language'
// const OPERATIONS_CREATING_ACCOUNT = ['GetActiveSession']

const getHeaders = (accessToken?: string): Record<string, unknown> => {
  const headers = {
    'Apollo-Require-Preflight': '"true"',
    [SELECTED_LANGUAGE_PARAM_NAME]: getLanguageCode(),
    Authorization: authorizationHeader(accessToken ?? getAccessToken()),
  }
  return pickBy(headers, identity)
}

const accessTokenField = 'getAccessRefreshToken'

export const fetchAccessRefreshToken = async (
  create = false
): Promise<string | null> => {
  const res = await fetch(
    process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4000/graphql',
    {
      method: 'post',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        query: GetAccessRefreshTokenDocument.loc?.source.body,
        variables: {
          create: process.env.REACT_APP_IS_RESEARCH_INSTANCE ? false : create,
        } satisfies GetAccessRefreshTokenQueryVariables,
      }),
    }
  )
  const { data } = await res.json()
  const accessToken = data[accessTokenField]

  return accessToken
}

const wsClient = createSubscriptionClient({
  url:
    process.env.REACT_APP_GRAPHQL_ENDPOINT_WS || 'ws://localhost:4000/graphql',
  connectionParams: getHeaders,
})
const wsLink = new GraphQLWsLink(wsClient)

export const subscribeNewAccessToken = async (accessToken?: string) => {
  await wsClient?.sendAccessToken(accessToken ?? '')
}

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getHeaders(),
    },
  }
})

const httpLink = createUploadLink({
  uri:
    process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4000/graphql',
  fetchOptions: {
    credentials: 'include',
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    const accessTokenRefreshDefinitionName =
      ReauthenticateSocketDocument.definitions.at(0)?.['name']?.['value']
    return (
      (definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription') ||
      accessTokenRefreshDefinitionName === definition.name?.value
    )
  },
  wsLink,
  httpLink
)

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, splitLink]),
  cache: new InMemoryCache(),
})

import classNames from 'classnames'
import type { FC, HTMLAttributes } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AuthError,
  ProfileError,
  type UpdatePasswordMutationVariables,
} from '../../GraphQL/graphql'
import { authErrorToMessage, passwordRegex } from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { useProfile } from '../../Hooks/useProfile'
import { AlertMessage } from '../AlertMessage/AlertMessage'

export const ProfileUpdatePassword: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...divArgs
}) => {
  const { t } = useTranslation()

  const [editPassword, setEditPassword] = useState(false)

  const {
    mutations: {
      updatePassword: [updatePassword],
    },
  } = useProfile()

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    getValues,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<UpdatePasswordMutationVariables & { passwordRepeat: string }>()

  const onSubmit = handleSubmit(
    async ({ password, passwordRepeat, oldPassword }) => {
      if (password !== passwordRepeat) {
        setError('passwordRepeat', {
          message: t('The password repetition must match the new password.'),
        })
        return
      }
      await updatePassword({
        variables: {
          password,
          oldPassword,
        },
        onCompleted: ({ updatePassword }) => {
          if (updatePassword.authError) {
            switch (updatePassword.authError) {
              case AuthError.InvalidPassword:
                setError('oldPassword', {
                  message: authErrorToMessage(updatePassword.authError, t),
                })
                break
              default:
                setError('root', {
                  message: authErrorToMessage(updatePassword.authError, t),
                })
            }
          } else if (updatePassword.profileErrors) {
            const errors: string[] = []
            updatePassword.profileErrors.forEach((error) => {
              switch (error) {
                case ProfileError.InvalidPasswordFormat:
                  setError('password', {
                    message: profileErrorToMessage(error, t),
                  })
                  break
                default:
                  errors.push(profileErrorToMessage(error, t))
                  break
              }
              if (errors.length) {
                setError('root', {
                  message: errors.join(' | '),
                })
              }
            })
          } else {
            setEditPassword(false)
            reset()
          }
        },
        onError: () =>
          setError('root', {
            message: t(
              'Saving not possible. If this error occurs repeatedly, please contact our support.'
            ),
          }),
      })
    }
  )

  return (
    <div {...divArgs}>
      <h3>Passwort</h3>

      {isSubmitSuccessful && !isDirty && (
        <AlertMessage
          type="success"
          message={t('Password successfully saved.')}
          onClose={() => reset()}
        />
      )}
      {editPassword && (
        <>
          {errors.root?.message && (
            <AlertMessage
              type="warning"
              message={errors.root.message}
              onClose={() => clearErrors('root')}
            />
          )}
        </>
      )}
      <form onSubmit={onSubmit}>
        <fieldset className="my-[13px]">
          {editPassword && (
            <>
              <label htmlFor="oldPassword" className="invisible">
                {t('Current password')}
              </label>
              <input
                className={classNames(
                  editPassword ? 'active_input' : 'input',
                  errors.oldPassword && '!border-red-600 !border-2'
                )}
                disabled={isSubmitting || !editPassword}
                type="password"
                placeholder={t('Current password')}
                {...register('oldPassword', {
                  required: t('This field cannot be empty.'),
                })}
              />
              {errors.oldPassword && (
                <label
                  htmlFor="oldPassword"
                  className="text-red-600 flex justify-end text-right"
                >
                  {errors.oldPassword.message ||
                    t('This field cannot be empty.')}
                </label>
              )}

              <label htmlFor="password" className="invisible">
                {t('New password')}
              </label>
              <input
                className={classNames(
                  editPassword ? 'active_input' : 'input',
                  errors.password && '!border-red-600 !border-2'
                )}
                disabled={isSubmitting || !editPassword}
                type="password"
                placeholder={t('New password')}
                {...register('password', {
                  required: t('This field cannot be empty.'),
                  pattern: {
                    value: passwordRegex,
                    message: profileErrorToMessage(
                      ProfileError.InvalidPasswordFormat,
                      t
                    ),
                  },
                })}
              />
              {errors.password && (
                <label
                  htmlFor="password"
                  className="text-red-600 flex justify-end text-right"
                >
                  {errors.password.message || t('This field cannot be empty.')}
                </label>
              )}

              <label htmlFor="passwordRepeat" className="invisible">
                {t('Repeat password')}
              </label>
              <input
                className={editPassword ? 'active_input' : 'input'}
                disabled={isSubmitting || !editPassword}
                type="password"
                placeholder={t('Repeat password')}
                {...register('passwordRepeat', {
                  required: t('This field cannot be empty.'),
                  validate: (passwordRepeat) => {
                    return getValues('password') !== passwordRepeat
                      ? t(
                          'The password repetition must match the new password.'
                        )
                      : undefined
                  },
                })}
              />
              {errors.passwordRepeat && (
                <label
                  htmlFor="passwordRepeat"
                  className="text-red-600 flex justify-end text-right"
                >
                  {errors.passwordRepeat.message ||
                    t('This field cannot be empty.')}
                </label>
              )}

              {editPassword && (
                <button
                  type="submit"
                  className="mr-4 button-primary button-m"
                  disabled={isSubmitting || !isDirty}
                >
                  {isSubmitting ? t('Saving…') : 'Passwort ändern'}
                </button>
              )}
            </>
          )}

          <button
            type="button"
            className={classNames(
              'button-m mt-3',
              editPassword ? 'button-secondary' : 'button-primary'
            )}
            onClick={() => {
              reset()
              setEditPassword(!editPassword)
            }}
          >
            {editPassword ? 'Abbrechen' : 'Bearbeiten'}
          </button>
        </fieldset>
      </form>
    </div>
  )
}

import { CheckIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button } from '../../Components/Button'
import { Shell } from '../../Components/Shell/Shell'
import type { Currency } from '../../GraphQL/graphql'
import { moneyToString } from '../../Helper/TextHelper'
import { useGtm } from '../../Hooks/useGtm'
import { useProductSetForSession } from '../../Hooks/useProductSet'
import { Alert } from '../../Modules/Alert/Alert'
import { Loading } from '../../Modules/Loading/Loading'

export const Checkout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const [searchParams] = useSearchParams()
  const nextUrl = searchParams.get('next')
  const { loading, session, productSet, error, mutations } =
    useProductSetForSession(sessionId)
  const { trackOrderEvent } = useGtm()

  const [unfreezeSession, { loading: unfreezing }] = mutations.unfreezeSession
  const [createOrder, { loading: creating }] = mutations.createOrder

  useEffect(() => {
    if (session?.report) {
      if (session.report.orders.length === 1) {
        navigate(`/orders/${session.report.orders[0].id}/payment`)
      } else {
        navigate(`/reports/${session.report.id}/orders`)
      }
    }
  }, [navigate, session])

  const totalPrice = useMemo(() => {
    const grouped = _.groupBy(productSet?.patientProducts, (p) => p.currency)
    return Object.keys(grouped)
      .flatMap((currency) =>
        moneyToString(
          _.sumBy(grouped[currency], (p) => p.price ?? 0),
          currency as Currency
        )
      )
      .join(' | ')
  }, [productSet])

  const totalVat = useMemo(() => {
    const grouped = _.groupBy(productSet?.patientProducts, (p) => p.currency)
    return Object.keys(grouped)
      .flatMap((currency) =>
        moneyToString(
          _.sumBy(grouped[currency], (p) => p.vat ?? 0),
          currency as Currency
        )
      )
      .join(' | ')
  }, [productSet])

  const [appraisalContract, setAppraisalContract] = useState(false)
  const [withdrawalRight, setWithdrawalRight] = useState(false)
  const [dataProtection, setDataProtection] = useState(false)
  const [mayContact, setMayContact] = useState(false)
  const [shareData, setShareData] = useState(false)

  if (loading) {
    return (
      <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
        <Loading />
      </Shell>
    )
  }

  if (error) {
    return (
      <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
        <Alert message={t('Could not load order summary.')} />
        {sessionId && (
          <Button
            onClick={async () => {
              const data = await unfreezeSession({
                variables: { sessionId },
              })
              if (!data.data?.unfreezeSession.error) {
                // TODO: implement via websocket
                nextUrl ? navigate(nextUrl) : navigate(-1)
              }
            }}
            variant="secondary"
            outline
            disabled={unfreezing}
          >
            {unfreezing ? t('Loading...') : t('Back')}
          </Button>
        )}
      </Shell>
    )
  }

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
      <div className="mx-auto space-y-3 text-dro-blue">
        <h1 className="font-noto-serif text-4xl font-medium mb-5">
          {t('Overview')}
        </h1>
        {/* <h1 className="font-noto-serif text-4xl text-dro-blue font-medium mb-5">
          {`${t('Checkout')}: ${productSet.title}`}
        </h1> */}
        {/* <h2 className="font-noto-serif text-1xl text-dro-blue font-medium mb-5">
          {`${t('Diagnosis date')}: ${dateToString(
            session.lastActiveTimestamp
          )}`}
        </h2> */}
        <p>
          Wir klären für Sie die Fragestellung „Inwieweit entspricht die mir
          vorliegende Indikation zur Knie-TEP der nationalen Leitlinie?“ unter
          ausschließlicher Berücksichtigung der von Ihnen hier gemachten
          Angaben.
        </p>
        <table className="">
          <tbody>
            {productSet?.patientProducts.map((product) => (
              <tr key={product.id}>
                <td>{product.title}</td>
                <td className="text-right">
                  {moneyToString(product.price, product.currency)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>{t('Order amount')}</td>
              <td className="text-right">{totalPrice}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                In der Auftragssumme sind {totalVat} USt enthalten.{' '}
              </td>
            </tr>
          </tfoot>
        </table>
        <p>
          Folgender Begutachtungsvertrag mit Widerrufsrecht liegt meiner
          Beauftragung zugrunde:{' '}
          <a
            href="https://docrobin.net/stage/begutachtungsvertrag/"
            target="_blank"
            rel="noreferrer"
            className="dro-link"
          >
            Begutachtungsvertrag
          </a>
          ,{' '}
          <a
            href="https://docrobin.net/stage/begutachtungsvertrag#anlage_1"
            target="_blank"
            rel="noreferrer"
            className="dro-link"
          >
            Widerrufsrecht
          </a>
        </p>
        <p>
          Mit ist bekannt, dass mein Widerrufsrecht mit vollständiger
          Vertragserfüllung durch den Experten erlischt.
        </p>
        <div className="pl-8">
          <label
            aria-checked={appraisalContract}
            className="relative flex items-start gap-3 mb-2 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent"
            onClick={() => {
              setAppraisalContract(!appraisalContract)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              Dem Begutachtungsvertrag stimme ich zu. (notwendig)
            </div>
          </label>
          <label
            aria-checked={withdrawalRight}
            className="relative flex items-start gap-3 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent"
            onClick={() => {
              setWithdrawalRight(!withdrawalRight)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              Dem Widerrufsrecht stimme ich zu. (notwendig)
            </div>
          </label>
        </div>
        <p>
          Ich bin mit der Datenspende gemäß dieser{' '}
          <a
            href="https://docrobin.net/stage/datenschutzrechtliche-einwilligungserklaerung-zur-datenspende/"
            target="_blank"
            rel="noreferrer"
            className="dro-link"
          >
            Datenschutzrechtlichen Einwilligungserklärung
          </a>
        </p>
        <div className="pl-8 mb-[2rem_!important]">
          <label
            aria-checked={dataProtection}
            className="relative flex items-start gap-3 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent mb-2"
            onClick={() => {
              setDataProtection(!dataProtection)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              Meine bei Nutzung dieses Portals gemachten Angaben dürfen zu den
              darin genannten Zwecken unter Einhaltung aller
              datenschutzrechtlicher Bestimmungen weiter verwendet werden.
              (optional)
            </div>
          </label>
          <label
            aria-checked={mayContact}
            className="relative flex items-start gap-3 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent mb-4"
            onClick={() => {
              setMayContact(!mayContact)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              Der Service von DocRobin darf mich in regelmäßigen Abständen
              kontaktieren, um meine Informationen über meinen weiteren
              Behandlungsverlauf sowie meinen Gesundheitszustand nach Abschluss
              des Zweitgutachtens zu den oben genannten Zwecken unter Einhaltung
              aller datenschutzrechtlicher Bestimmungen abzufragen. (optional)
            </div>
          </label>
          <label
            aria-checked={shareData}
            className="relative flex items-start gap-3 px-5 text-dro-blue  sm:px-0 group aria-checked:sm:bg-transparent"
            onClick={() => {
              setShareData(!shareData)
            }}
          >
            <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green mt-1">
              <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
            </div>
            <div className="flex-1 min-w-0 text-left">
              Mit dem Datenaustausch zwischen DocRobin und dem Zweitgutachter
              gemäß dieser Einwilligungserklärung bin ich einverstanden.
              (notwendig)
            </div>
          </label>
        </div>
        {session?.report && (
          <Button
            to={
              session.report.orders.length === 1
                ? `/orders/${session.report.orders[0].id}/payment`
                : `/reports/${session.report.id}/orders`
            }
          >
            {t('Pay now')}
          </Button>
        )}
        {session && !session.report && (
          <>
            <Button
              className="mr-4"
              disabled={
                creating || !appraisalContract || !withdrawalRight || !shareData
              }
              onClick={async () => {
                const data = await createOrder({
                  variables: {
                    sessionId: session?.id,
                    productSetId: productSet?.id,
                    allowsFollowUp: mayContact,
                    allowsDataProcessing: dataProtection,
                  },
                })
                if (data.data?.createOrder.order) {
                  await trackOrderEvent()
                  navigate(`/orders/${data.data.createOrder.order.id}/payment`)
                }
              }}
            >
              {t('Order with costs')}
            </Button>{' '}
            <Button
              onClick={async () => {
                const data = await unfreezeSession({
                  variables: { sessionId: session.id },
                })
                if (!data.data?.unfreezeSession.error) {
                  // TODO: implement via websocket
                  nextUrl ? navigate(nextUrl) : navigate(-1)
                }
              }}
              variant="secondary"
              outline
              disabled={unfreezing}
            >
              {unfreezing ? t('Loading...') : t('Back')}
            </Button>
          </>
        )}
      </div>
    </Shell>
  )
}

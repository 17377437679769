import { useMutation } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import classNames from 'classnames'
import type { FC } from 'react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  RegisterUserAndSendEmailVerificationMutation,
  RegisterUserAndSendEmailVerificationMutationVariables,
} from '../../GraphQL/graphql'
import {
  AuthError,
  ProfileError,
  RegisterUserAndSendEmailVerificationDocument,
} from '../../GraphQL/graphql'
import { authErrorToMessageZod } from '../../Helper/AuthHelper'
import { profileErrorToMessageZod } from '../../Helper/UserHelper'
import { AlertMessage } from '../AlertMessage/AlertMessage'

const registrationFormSchena = z
  .object({
    email: z
      .string()
      .trim()
      .min(1, 'This field cannot be empty.')
      .email('Wrong format.'),
    password: z.string().trim().min(6, 'At least 6 characters.'),
    passwordRepeat: z.string().trim().min(6, 'At least 6 characters.'),
  })
  .refine((data) => data.password === data.passwordRepeat, {
    message: 'The password repetition must match the new password.',
    path: ['passwordRepeat'],
  })

type RegistrationFormValue = z.infer<typeof registrationFormSchena>

export const RegisterEnterEmailPassword: FC = () => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)
  const [searchParams] = useSearchParams()
  const nextPath = searchParams.get('next')

  const [agreesTerms, setAgreesTerms] = useState(false)
  const [agreesPolicy, setAgreesPolicy] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<RegistrationFormValue>({
    mode: 'onBlur',
    defaultValues: {
      email: undefined,
      password: undefined,
      passwordRepeat: undefined,
    },
    resolver: zodResolver(registrationFormSchena),
  })

  const [registerUserAndSendEmailVerification, { loading }] = useMutation<
    RegisterUserAndSendEmailVerificationMutation,
    RegisterUserAndSendEmailVerificationMutationVariables
  >(RegisterUserAndSendEmailVerificationDocument)

  const onSubmit = handleSubmit(async ({ email, password, passwordRepeat }) => {
    if (password !== passwordRepeat) {
      setError('passwordRepeat', {
        message: t('The password repetition must match the new password.'),
      })
      return
    }
    await registerUserAndSendEmailVerification({
      variables: {
        email,
        password,
        next: nextPath,
      },
      onCompleted: ({ registerUserAndSendEmailVerification }) => {
        if (registerUserAndSendEmailVerification.authError) {
          switch (registerUserAndSendEmailVerification.authError) {
            case AuthError.ExistingUser:
              setError('email', {
                message: authErrorToMessageZod(
                  registerUserAndSendEmailVerification.authError
                ),
              })
              break
            default:
              setError('root', {
                message: authErrorToMessageZod(
                  registerUserAndSendEmailVerification.authError
                ),
              })
          }
        } else if (registerUserAndSendEmailVerification.profileErrors) {
          const errors: string[] = []
          registerUserAndSendEmailVerification.profileErrors.forEach(
            (error) => {
              switch (error) {
                case ProfileError.InvalidEmailFormat:
                  setError('email', {
                    message: profileErrorToMessageZod(error),
                  })
                  break
                case ProfileError.InvalidPasswordFormat:
                  setError('password', {
                    message: profileErrorToMessageZod(error),
                  })
                  break
                default:
                  errors.push(profileErrorToMessageZod(error))
                  break
              }
              if (errors.length) {
                setError('root', {
                  message: errors.join(' | '),
                })
              }
            }
          )
        } else if (registerUserAndSendEmailVerification.accessToken) {
          updateAuth(registerUserAndSendEmailVerification.accessToken)
        }
      },
      onError: () =>
        setError('root', {
          message: t(
            'Saving not possible. If this error occurs repeatedly, please contact our support.'
          ),
        }),
    })
  })

  return (
    <>
      {errors.root?.message && (
        <div className="mb-10">
          <AlertMessage
            type="warning"
            message={t(errors.root.message as any)}
            onClose={() => clearErrors('root')}
          />
        </div>
      )}

      <form onSubmit={onSubmit}>
        <div className="flex flex-col mb-[50px]">
          <div className="mb-6">
            <label
              htmlFor="email"
              className="color-3 text-lg cursor-pointer inline-block mb-2"
            >
              {t('Email')}
            </label>
            <input
              className={classNames(
                'input',
                errors.email && '!border-red-600 !border-2'
              )}
              id="email"
              disabled={isSubmitting}
              type="text"
              placeholder={t('Email')}
              {...register('email')}
            />
            {errors.email?.message && (
              <label
                htmlFor="email"
                className="text-red-600 mt-1 flex justify-end"
              >
                {t(errors.email.message as any)}
              </label>
            )}
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="color-3 text-lg cursor-pointer mb-2 inline-block"
            >
              {t('New password')}
            </label>
            <input
              className={classNames(
                'input',
                errors.password && '!border-red-600 !border-2'
              )}
              disabled={isSubmitting}
              type="password"
              id="password"
              placeholder={t('New password')}
              {...register('password')}
            />
            {errors.password?.message && (
              <label
                htmlFor="password"
                className="text-red-600 flex mt-1 justify-end text-right"
              >
                {t(errors.password.message as any)}
              </label>
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="passwordRepeat" className="invisible hidden">
              {t('Repeat password')}
            </label>
            <input
              className="input"
              disabled={isSubmitting}
              type="password"
              id="passwordRepeat"
              placeholder={t('Repeat password')}
              {...register('passwordRepeat')}
            />
            {errors.passwordRepeat?.message && (
              <label
                htmlFor="passwordRepeat"
                className="text-red-600 flex justify-end mt-1 text-right"
              >
                {t(errors.passwordRepeat.message as any)}
              </label>
            )}
          </div>
          <div className="flex items-start flex-col mb-8">
            <label
              aria-checked={agreesTerms}
              className="relative flex items-center gap-3 px-5 py-3 text-gray-900 sm:py-2 sm:px-0 group aria-checked:sm:bg-transparent"
              onClick={() => {
                setAgreesTerms(!agreesTerms)
              }}
            >
              <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green">
                <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
              </div>
              <div className="flex-1 min-w-0 text-left">
                <Trans
                  i18nKey="I agree to the terms of use and privacy policy."
                  components={{
                    lnk_terms: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={t('company-terms-of-use')}
                        target="_blank"
                        className="underline"
                        rel="noreferrer"
                      />
                    ),
                    lnk_privacy: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={t('company-privacy-policy')}
                        className="underline"
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                  }}
                />
              </div>
            </label>
            <label
              aria-checked={agreesPolicy}
              className="relative flex items-center gap-3 px-5 py-3 text-gray-900 sm:py-2 sm:px-0 group aria-checked:sm:bg-transparent"
              onClick={() => {
                setAgreesPolicy(!agreesPolicy)
              }}
            >
              <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green">
                <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
              </div>
              <div className="flex-1 min-w-0 text-left">
                <Trans
                  i18nKey="I agree to the privacy policy."
                  components={{
                    lnk_privacy: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={t('company-privacy-policy')}
                        className="underline"
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                  }}
                />
              </div>
            </label>
          </div>
          <button
            disabled={
              isSubmitting ||
              !isDirty ||
              loading ||
              !agreesTerms ||
              !agreesPolicy
            }
            type="submit"
            className="button-primary button-l disabled:opacity-80 disabled:cursor-not-allowed"
          >
            {isSubmitting ? t('Saving…') : t('Sign up for free')}
          </button>
        </div>
      </form>
    </>
  )
}

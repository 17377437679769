import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { ActiveSessionFragment } from '../../GraphQL/graphql'
import { ConversationError } from '../../GraphQL/graphql'
import type { UseSessionStateType } from '../../Hooks/useActiveSession'
import { ChatInputInner } from './ChatInputInner'

interface Props {
  activeSession: ActiveSessionFragment
  useSessionState?: UseSessionStateType
  onAnswerCallback?: () => void
}

const FOOTER_HEIGHT_CLASS = 'max-h-[40vh]'

export const ChatInput: React.FC<Props> = ({
  activeSession,
  useSessionState,
  onAnswerCallback,
}) => {
  const { t } = useTranslation()

  const containerRef = useRef<HTMLDivElement>(null)

  const [state, _] = useSessionState ?? []
  const [isOverflowingTop, setIsOverflowingTop] = useState(false)
  const [isOverflowingBottom, setIsOverflowingBottom] = useState(false)

  const isOverflowing = isOverflowingTop || isOverflowingBottom

  const checkOverflow = useCallback((element?: HTMLElement | null) => {
    if (!element) {
      setIsOverflowingTop(false)
      setIsOverflowingBottom(false)
      return
    }

    setIsOverflowingTop(element.scrollTop > 0)
    setIsOverflowingBottom(
      element.scrollHeight - element.scrollTop > element.clientHeight
    )
  }, [])

  useEffect(() => {
    if (!containerRef.current) {
      checkOverflow()
      return
    }

    const resizeObserver = new ResizeObserver(() =>
      checkOverflow(containerRef.current)
    )
    resizeObserver.observe(containerRef.current)

    return () => resizeObserver.disconnect()
  }, [checkOverflow])

  return (
    <div
      ref={containerRef}
      className={classNames(
        'max-md:py-2 max-md:px-4 py-4 px-8 sticky bottom-0 w-full flex overflow-y-auto bg-old-color-8 overscroll-contain min-h-[72px] max-md:rounded-lg rounded-lg',
        FOOTER_HEIGHT_CLASS,
        isOverflowing && 'pr-20'
      )}
      onScroll={(event) => {
        checkOverflow(event.currentTarget)
      }}
    >
      <div className="flex self-start w-full">
        {state?.error ? (
          [
            ConversationError.ExpiredSession,
            ConversationError.ClosedSession,
          ].includes(state.error) && (
            <div className="alert-success">{t('The session has expired.')}</div>
          )
        ) : (
          <ChatInputInner
            activeSession={activeSession}
            useSessionState={useSessionState}
            onAnswerCallback={onAnswerCallback}
          />
        )}
      </div>
      {isOverflowing && (
        <div
          className={classNames(
            'fixed bottom-0 right-0 flex flex-col items-center justify-between w-20 h-full py-4 pointer-events-none',
            FOOTER_HEIGHT_CLASS
          )}
        >
          <button
            className="p-4 bg-white border border-gray-300 rounded-full shadow pointer-events-auto disabled:opacity-50"
            disabled={!isOverflowingTop}
            onClick={() => {
              containerRef.current?.scrollTo({
                top: containerRef.current.scrollTop - 150,
                behavior: 'smooth',
              })
            }}
          >
            <ChevronUpIcon className="w-6 h-6" />
          </button>
          <button
            className="p-4 bg-white border border-gray-300 rounded-full shadow pointer-events-auto disabled:opacity-50"
            disabled={!isOverflowingBottom}
            onClick={() => {
              containerRef.current?.scrollTo({
                top: containerRef.current.scrollTop + 150,
                behavior: 'smooth',
              })
            }}
          >
            <ChevronDownIcon className="w-6 h-6" />
          </button>
        </div>
      )}
    </div>
  )
}

import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { useCallback, useRef, useState } from 'react'
import { A11yDialog } from 'react-a11y-dialog'
import type A11yDialogInstance from 'react-a11y-dialog/dist/index'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import type {
  UploadDicomFilesMutation,
  UploadDicomFilesMutationVariables,
} from '../../GraphQL/graphql'
import { UploadDicomFilesDocument } from '../../GraphQL/graphql'
import { DIALOG_SIZE, getDialogOptions } from '../../Helper/DialogHelper'
import type { DicomSeriesFiles } from '../../Helper/DicomHelper'
import { parseDicomSeries } from '../../Helper/DicomHelper'
import { Button } from '../Button'

interface Props {
  onFinish(): void
}

export const DicomUpload: React.FC<Props> = ({ onFinish }) => {
  const { t } = useTranslation()
  const [uploadDicom] = useMutation<
    UploadDicomFilesMutation,
    UploadDicomFilesMutationVariables
  >(UploadDicomFilesDocument)

  const [parsedSeries, setParsedSeries] = useState<DicomSeriesFiles[]>([])
  const [showOnlyXRayWarning, setShowOnlyXRayWarning] = useState(false)

  const exampleImagesDialogRef = useRef<A11yDialogInstance>(null)

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    const series = await parseDicomSeries(acceptedFiles)
    const onlyCtSeries = series.filter((s) => s.studyModality === 'CR')

    if (onlyCtSeries.length !== series.length) {
      setShowOnlyXRayWarning(true)
    }

    setParsedSeries(onlyCtSeries)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  })

  const closeModal = () => {
    setParsedSeries([])
    onFinish()
    setShowOnlyXRayWarning(false)
  }

  const handleUpload = async () => {
    const files = parsedSeries
      .concat()
      .map((s) => s.files.map((f) => f.file))
      .flat()

    await uploadDicom({ variables: { files } })

    closeModal()
  }

  return (
    <div className="DicomUpload">
      <div
        {...getRootProps()}
        className={classNames(
          'flex h-[192px] old-color-9 cursor-pointer border-4 border-dashed p-4 transition-colors mb-2',
          {
            'old-border-color-1': isDragActive,
            'old-border-color-5': !isDragActive,
          }
        )}
      >
        <input {...getInputProps()} />

        {
          <div className="self-center">
            {isDragActive ? (
              <p>{t('Drop files here')}</p>
            ) : (
              <p>{t('Drag’n’drop DICOM files or click to select')}</p>
            )}
          </div>
        }
      </div>
      {showOnlyXRayWarning && (
        <p className="mb-2">
          {t('Warning! Only x-ray images will be uploaded!')}
        </p>
      )}

      <p className="mb-3">
        <small>{t('file-upload-knees-only-help-text')}</small>
      </p>

      <div>
        <Button variant="danger" className="mr-4" onClick={closeModal}>
          {t('Cancel')}
        </Button>
        {parsedSeries.length > 0 ? (
          <Button onClick={handleUpload}>
            {t('Upload {{count}} file(s)', { count: parsedSeries.length })}
          </Button>
        ) : (
          <Button onClick={() => exampleImagesDialogRef.current.show()}>
            {t('Show sample images')}
          </Button>
        )}
      </div>
      <A11yDialog
        dialogRef={(instance) => (exampleImagesDialogRef.current = instance)}
        {...getDialogOptions('', 'dialog--success', DIALOG_SIZE.DEFAULT)}
      >
        <div className="overflow-y-auto h-full w-full px-4 flex flex-col justify-center">
          <div className="flex">
            <p>
              <img
                alt={t('Knee X-ray from the side')}
                style={{ width: '95%', height: '100%' }}
                src="https://prod-images-static.radiopaedia.org/images/14981298/9bd74560157d6fcf63e043d5872057_jumbo.jpeg"
              />
            </p>
            <p>
              <img
                alt={t('Knee X-ray from the front')}
                style={{ width: '100%', height: '100%' }}
                src="https://prod-images-static.radiopaedia.org/images/14981304/ac482d9c835c6b4c003dc7004fab8c_jumbo.jpeg"
              />
            </p>
          </div>
          <div className="flex justify-end items-end h-[15%]">
            <Button
              onClick={() => {
                exampleImagesDialogRef.current?.hide()
              }}
            >
              {t('Back')}
            </Button>
          </div>
        </div>
      </A11yDialog>
    </div>
  )
}

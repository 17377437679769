import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { useProfile } from '../../Hooks/useProfile'
import { Loading } from '../../Modules/Loading/Loading'
import { RegisterEnterEmailPassword } from '../RegisterEnterEmailPassword/RegisterEnterEmailPassword'
import { RegisterEnterProfileInfo } from '../RegisterEnterProfileInfo/RegisterEnterProfileInfo'
import { RegisterWaitEmailVerification } from '../RegisterWaitEmailVerification/RegisterWaitEmailVerification'
import { Shell } from '../Shell/Shell'

enum RegisterState {
  EnterEmailPassword,
  WaitEmailVerification,
  EnterProfileInfo,
  // VerifyPhone,
}

export const Register: React.FC = () => {
  const { profile, profileLoading } = useProfile()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [formState, setFormState] = useState<RegisterState>(
    RegisterState.EnterEmailPassword
  )

  useEffect(() => {
    if (!profile || !(profile.pendingEmail || profile.email)) {
      setFormState(RegisterState.EnterEmailPassword)
    } else if (!profile.isEmailVerified) {
      setFormState(RegisterState.WaitEmailVerification)
    } else if (!(profile.pendingPhone || profile.phone)) {
      setFormState(RegisterState.EnterProfileInfo)
      // } else if (!profile.isPhoneVerified) {
      //   setFormState(RegisterState.VerifyPhone)
    }
  }, [profile])

  if (profileLoading) {
    return <Loading />
  }

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]}>
      <div className="flex flex-col min-[650px]:w-[555px] mx-auto">
        <h1 className="color-3 text-[64px]/[72px] text-center">
          {formState === RegisterState.EnterEmailPassword && t('Sign up')}
          {formState === RegisterState.WaitEmailVerification &&
            t('Verify email')}
          {formState === RegisterState.EnterProfileInfo &&
            t('About the person')}
        </h1>
        <div className="flex justify-center mt-5 mb-6">
          {formState === RegisterState.EnterEmailPassword && (
            <>
              <p className="mr-4 text-lg color-3 inline-block">
                {t('Already signed up?')}
              </p>
              <Link
                className="inline-block text-lg link"
                to={`/login?${searchParams}`}
              >
                {t('Login')}
              </Link>
            </>
          )}
        </div>
        {formState === RegisterState.EnterEmailPassword && (
          <RegisterEnterEmailPassword />
        )}

        {formState === RegisterState.WaitEmailVerification && (
          <RegisterWaitEmailVerification profile={profile} />
        )}

        {formState === RegisterState.EnterProfileInfo && (
          <RegisterEnterProfileInfo profile={profile} />
        )}

        {/* {formState === RegisterState.VerifyPhone && (
          <>
            <RegisterEnterProfileInfo
              isDisabled
              smallPaddingBottom
              profile={profile}
            />
            <RegisterVerifyPhone />
          </>
        )} */}
      </div>
    </Shell>
  )
}

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import type {
  DeleteLastUserResponseMutation,
  DeleteLastUserResponseMutationVariables,
} from '../../GraphQL/graphql'
import { DeleteLastUserResponseDocument } from '../../GraphQL/graphql'
import type { UseSessionStateType } from '../../Hooks/useActiveSession'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  sessionId: string
  lastElementId?: string
  useSessionState?: UseSessionStateType
}

export const DeleteChatButton: React.FC<Props> = ({
  sessionId,
  lastElementId,
  useSessionState,
  children,
  ...props
}) => {
  const { t } = useTranslation()
  const [sessionState, setSessionState] = useSessionState ?? []

  const [deleteLastConversations] = useMutation<
    DeleteLastUserResponseMutation,
    DeleteLastUserResponseMutationVariables
  >(DeleteLastUserResponseDocument, {
    variables: { sessionId, lastElementId },
    onCompleted: (data) =>
      setSessionState &&
      data.deleteLastUserResponse.error &&
      setSessionState({
        loading: false,
        error: data.deleteLastUserResponse.error ?? undefined,
      }),
  })

  return (
    <button
      disabled={props.disabled && !sessionId && sessionState?.loading}
      onClick={(e) => {
        if (sessionId) {
          setSessionState && setSessionState({ loading: true })
          deleteLastConversations()
          props.onClick && props.onClick(e)
        }
      }}
      {...props}
    >
      {children ?? t('Change answer')}
    </button>
  )
}

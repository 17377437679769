import { type TFunction } from 'i18next'
import { ProfileError } from '../GraphQL/graphql'

export const profileErrorToMessage = (
  error: ProfileError,
  t: TFunction
): string => {
  // TODO: translate ProfileErrors
  switch (error) {
    case ProfileError.InvalidBirthdateFormat:
      return t('Wrong format.') + ' ' + t('You must be at least 18 years old.')
    case ProfileError.InvalidCityFormat:
      return t('Wrong format.')
    case ProfileError.InvalidCountryFormat:
      return t('Wrong format.')
    case ProfileError.InvalidEmailFormat:
      return t('Wrong format.')
    case ProfileError.InvalidFirstnameFormat:
      return t('Wrong format.')
    case ProfileError.InvalidInstructionFormat:
      return t('Wrong format.')
    case ProfileError.InvalidLastnameFormat:
      return t('Wrong format.')
    case ProfileError.InvalidPasswordFormat:
      return t('Wrong format.') + ' ' + t('At least 6 characters.')
    case ProfileError.InvalidPhoneFormat:
      return (
        t('Wrong format.') +
        ' ' +
        t('Including country code, e.g. +491234567890')
      )
    case ProfileError.InvalidStreetFormat:
      return 'Die Straße der Adresse muss aus mindestens einem Buchstaben bestehen.'
    case ProfileError.InvalidTitleFormat:
      return 'Fehler beim Namenstitel.'
    case ProfileError.InvalidZipFormat:
      return 'Die Postleitzahl ist nicht gültig.'
    case ProfileError.UnsupportedCountry:
      return 'Dieses Land wird noch nicht unterstützt.'
  }
  return t('Error')
}

export const profileErrorToMessageZod = (error: ProfileError): string => {
  switch (error) {
    case ProfileError.InvalidBirthdateFormat:
      return 'You must be at least 18 years old.'
    case ProfileError.InvalidCityFormat:
      return 'Wrong format.'
    case ProfileError.InvalidCountryFormat:
      return 'Wrong format.'
    case ProfileError.InvalidEmailFormat:
      return 'Wrong format.'
    case ProfileError.InvalidFirstnameFormat:
      return 'Wrong format.'
    case ProfileError.InvalidInstructionFormat:
      return 'Wrong format.'
    case ProfileError.InvalidLastnameFormat:
      return 'Wrong format.'
    case ProfileError.InvalidPasswordFormat:
      return 'At least 6 characters.'
    case ProfileError.InvalidPhoneFormat:
      return 'Including country code, e.g. +491234567890'
    case ProfileError.InvalidStreetFormat:
      return 'Die Straße der Adresse muss aus mindestens einem Buchstaben bestehen.'
    case ProfileError.InvalidTitleFormat:
      return 'Fehler beim Namenstitel.'
    case ProfileError.InvalidZipFormat:
      return 'Die Postleitzahl ist nicht gültig.'
    case ProfileError.UnsupportedCountry:
      return 'Dieses Land wird noch nicht unterstützt.'
  }
  return 'Error'
}

import { useMutation } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import type {
  ReportFragment,
  ShareReportFragment,
  ShareReportMutation,
} from '../../GraphQL/graphql'
import { ShareReportDocument } from '../../GraphQL/graphql'
import { Button } from '../Button'
import { Card } from '../Card/Card'
import { MdRenderer } from '../MdRenderer/MdRenderer'

interface Props {
  report?: ReportFragment | ShareReportFragment | null
  sharing?: boolean
}

export const ReportContent: React.FC<Props> = ({ report, sharing = true }) => {
  const [shareReport] = useMutation<ShareReportMutation>(ShareReportDocument)
  const { t } = useTranslation()

  const [isShareLoading, setIsShareLoading] = useState(false)
  const reportShareSecret = useRef<string | null | undefined>(
    report?.shareSecret
  )
  const handleShare = (stop: boolean) => {
    setIsShareLoading(true)
    shareReport({ variables: { reportId: report?.id, stop: stop } })
  }

  useEffect(() => {
    if (report?.shareSecret !== reportShareSecret.current) {
      reportShareSecret.current = report?.shareSecret

      setIsShareLoading(false)
    }
  }, [report?.shareSecret])

  if (!report) return <>{}</>

  return (
    <>
      {report.released && report.text ? (
        <div>
          <MdRenderer mdText={report.text} styling />
        </div>
      ) : (
        <>{t('Report not released yet')}</>
      )}

      {sharing && (
        <>
          {report.shareSecret &&
          new Date(report.shareExpirationTimestamp) > new Date() ? (
            <Card className="bg-white">
              <p className="text-2xl">
                {t('The report is currently being shared')}
              </p>
              <p>
                {t('Time remaining')}:{' '}
                <Countdown date={report.shareExpirationTimestamp} />
              </p>
              <div className="my-4">
                <div className="h-[auto] my-0 mx-[auto] max-w-[150px] w-full">
                  <QRCode
                    size={256}
                    style={{
                      height: 'auto',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                    value={`${process.env.REACT_APP_PUBLIC_URL}/reports/share/${report.shareSecret}`}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <p className="text-center break-anywhere mt-4">
                  <a
                    href={`${process.env.REACT_APP_PUBLIC_URL}/reports/share/${report.shareSecret}`}
                    target="_blank"
                    rel="noreferrer"
                  >{`${process.env.REACT_APP_PUBLIC_URL}/reports/share/${report.shareSecret}`}</a>
                </p>
              </div>
              <div>
                <Button onClick={() => handleShare(true)}>
                  {isShareLoading ? t('Loading...') : t('Stop sharing')}
                </Button>
              </div>
            </Card>
          ) : (
            <div className="text-end print:hidden my-3">
              <Button
                variant="secondary"
                outline
                onClick={() => handleShare(false)}
              >
                {isShareLoading
                  ? t('Loading...')
                  : t('Share report for five minutes')}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  )
}

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../Components/Button'
import { ReportList } from '../../Components/ReportList/ReportList'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import { useReports } from '../../Hooks/useReports'
import { useSession } from '../../Hooks/useSession'
import { Alert } from '../../Modules/Alert/Alert'

export const Reports: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { reports, reportsLoading } = useReports()
  const { isSession, sessionLoading } = useSession()

  if (reportsLoading || sessionLoading) {
    return <Spinner />
  }

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
      <h1 className="font-noto-serif text-4xl text-dro-blue font-medium mb-5">
        {t('Medical reports')}
      </h1>

      {isSession && (
        <p className="my-5">
          <Button
            className="button-primary px-4 py-2"
            onClick={() => navigate('/anamnesis')}
          >
            {t('Show anamnesis of current conversation')}{' '}
          </Button>
        </p>
      )}
      {!reportsLoading && !reports.length && (
        <Alert message={t('No reports available')} />
      )}
      {!reportsLoading && !!reports.length && <ReportList reports={reports} />}
    </Shell>
  )
}

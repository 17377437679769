import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChatIcon } from '../assets/chat.svg'
import type { BaseReportFragment } from '../GraphQL/graphql'
import { CountryCode, InvoiceState } from '../GraphQL/graphql'
import { getReportTitle } from '../Helper/ReportHelper'
import { dateToString } from '../Helper/TextHelper'
import { Button } from './Button'
import { DownloadInvoiceButton } from './DownloadInvoiceButton/DownloadInvoiceButton'

interface ReportCardProps {
  report: BaseReportFragment
}

export const ReportCard: FC<ReportCardProps> = ({ report }) => {
  const { t } = useTranslation()

  const isPaid = useMemo(() => {
    return report.orders.some(
      ({
        summary: {
          patient: { state },
        },
      }) => state === InvoiceState.Paid || state === InvoiceState.PartiallyPaid
    )
  }, [report.orders])

  const isVoided = useMemo(() => {
    return report.orders.some(
      ({
        summary: {
          patient: { state },
        },
      }) => state === InvoiceState.Void || state === InvoiceState.PartiallyVoid
    )
  }, [report.orders])

  return (
    <div className="bg-dro-lightblue border rounded-4xl py-6 2xl:py-12 px-7 2xl:px-8 flex flex-col justify-between gap-6 2xl:gap-12">
      <div>
        {/** TODO: translation */}
        {!report.released && (
          <div className="py-2 px-4 bg-white/25 rounded-full text-white mb-6">
            Befundung ausstehend
          </div>
        )}
        {report.released && !isPaid && (
          <div className="py-2 px-4 bg-white/25 rounded-full text-white mb-6">
            Bezahlung ausstehend
          </div>
        )}
        <div className="font-noto-serif font-bold text-3xl 2xl:text-4xl text-white mb-12">
          {getReportTitle(t, report)}
        </div>
        {(report.releaseTimestamp ||
          report.requestTimestamp ||
          report.createdTimestamp) && (
          <table className="text-white text-xl 2xl:text-2xl font-medium w-full">
            <tr>
              <td>
                {report.releaseTimestamp
                  ? t('Release date')
                  : report.requestTimestamp
                  ? t('Request date')
                  : report.createdTimestamp
                  ? t('Commissioning date')
                  : ''}
                :
              </td>
              <td className="text-right ps-4">
                {dateToString(
                  report.releaseTimestamp ??
                    report.requestTimestamp ??
                    report.createdTimestamp,
                  CountryCode.De
                )}
              </td>
            </tr>
            {report.orders[0].patientInvoices[0].paidAt && (
              <tr>
                <td>{t('Paid at')}:</td>
                <td className="text-right ps-4">
                  {dateToString(
                    report.orders[0].patientInvoices[0].paidAt,
                    CountryCode.De
                  )}
                </td>
              </tr>
            )}
            {report.orders[0].patientInvoices[0].voidAt && (
              <tr>
                <td>{t('Cancellation Date')}:</td>
                <td className="text-right ps-4">
                  {dateToString(
                    report.orders[0].patientInvoices[0].voidAt,
                    CountryCode.De
                  )}
                </td>
              </tr>
            )}
          </table>
        )}
      </div>
      {report.released && isPaid && (
        <Button to={`/reports/${report.id}`} size="lg" className="mb-8">
          {t('Show expert opinion')}{' '}
        </Button>
      )}
      {
        <>
          {report.conversationSession && !isVoided && (
            <Button
              variant="secondary"
              outline
              to={`/anamnesis/${report.conversationSession.id}`}
            >
              {t('Show anamnesis')}{' '}
            </Button>
          )}
          <Button variant="secondary" outline to={`/chat/archive/${report.id}`}>
            {t('Go to conversation history')} <ChatIcon className="inline" />
          </Button>

          {report.orders.length === 1 && (
            <>
              {[InvoiceState.Created, InvoiceState.PartiallyPaid].includes(
                report.orders[0].summary.patient.state
              ) && (
                <Button to={`/orders/${report.orders[0].id}/payment`}>
                  {t('Pay now')}
                </Button>
              )}
              {report.released && (
                <DownloadInvoiceButton orderId={report.orders[0].id}>
                  {t('Download invoice')}
                </DownloadInvoiceButton>
              )}
            </>
          )}

          {report.orders.length > 1 && (
            <Button to={`/reports/${report.id}/orders`}>{t('Orders')}</Button>
          )}
        </>
      }
    </div>
  )
}

import { useMutation } from '@apollo/client'
import type { FC } from 'react'
import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  RegisterUserDiscardMailMutation,
  RegisterUserDiscardMailMutationVariables,
  UserFragment,
} from '../../GraphQL/graphql'
import { RegisterUserDiscardMailDocument } from '../../GraphQL/graphql'
import { Spinner } from '../Spinner/Spinner'

type Props = {
  profile?: UserFragment
}

export const RegisterWaitEmailVerification: FC<Props> = ({ profile }) => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)

  const [discardMail, { loading }] = useMutation<
    RegisterUserDiscardMailMutation,
    RegisterUserDiscardMailMutationVariables
  >(RegisterUserDiscardMailDocument, {
    async onCompleted(data) {
      if (data.registerUserDiscardMail.accessToken) {
        updateAuth(data.registerUserDiscardMail.accessToken)
      }
    },
  })

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <>
          {profile?.pendingEmail && (
            <p className="text-center text-lg color-3 mb-12">
              <Trans
                i18nKey="We have sent an activation link to {{email}}."
                components={{
                  br: <br />,
                }}
                values={{ email: profile?.pendingEmail }}
              />
            </p>
          )}
          <p className="text-center text-lg color-3 font-bold mb-12">
            {t('Please verify your E-Mail using the sent link')}
          </p>
          <p className="text-center text-lg color-3 mb-12">
            {t('Check spam folder')}
          </p>
          <div className="flex justify-center">
            <button
              className="button-secondary button-m w-fit"
              onClick={() => discardMail()}
            >
              {t('Change e-mail')}
            </button>
          </div>
        </>
      )}
    </>
  )
}

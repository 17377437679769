import type { PropsWithChildren } from 'react'
import type { Circle } from '../Layout/Layout'
import { Layout } from '../Layout/Layout'

interface ShellProps {
  autogeneratedCircles: number
  preGeneratedCircles: Array<Circle>
  noLayout?: boolean
}

export const Shell: React.FC<PropsWithChildren<ShellProps>> = ({
  children,
  autogeneratedCircles,
  preGeneratedCircles,
  noLayout,
}) => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <div className="flex flex-col bg-[#ffffff] min-h-screen">
        <Layout
          className="flex-1 px-[0.1rem] pt-4 lg:mx-6 lg:mt-4 lg:py-[30px] lg:px-14 max-[1024px]:bg-[transparent]"
          autogeneratedCircles={autogeneratedCircles}
          preGeneratedCircles={preGeneratedCircles}
          transparent={!!noLayout}
        >
          {/* <AppHeader className="lg:mb-2" /> */}
          {children}
        </Layout>
        {/* <AppFooter className="grow-1 shrink-1 mt-6 mb-[22px] ml-20 mr-[120px]" /> */}
      </div>
    )
  }

  return <>{children}</>
}

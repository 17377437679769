import classNames from 'classnames'
import { DateTime, Duration } from 'luxon'
import type { FC, HTMLAttributes } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ProfileError,
  type ProfileInput,
  ProfileSex,
} from '../../GraphQL/graphql'
import {
  authErrorToMessage,
  birthdateRegex,
  firstnameRegex,
  lastnameRegex,
} from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { useProfile } from '../../Hooks/useProfile'
import { AlertMessage } from '../AlertMessage/AlertMessage'
import { RadioGroup } from '../RadioGroup/RadioGroup'

export const ProfileUpdateProfile: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...divArgs
}) => {
  const { t } = useTranslation()

  const [editProfile, setEditProfile] = useState(false)

  const {
    profile,
    mutations: {
      updateProfile: [updateProfile],
    },
  } = useProfile()

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<ProfileInput>({
    defaultValues: useMemo(() => {
      return {
        sex: profile?.sex ?? undefined,
        title: profile?.title ?? undefined,
        firstname: profile?.firstname ?? undefined,
        lastname: profile?.lastname ?? undefined,
        birthdate: profile?.birthdate ?? undefined,
      }
    }, [
      profile?.sex,
      profile?.title,
      profile?.firstname,
      profile?.lastname,
      profile?.birthdate,
    ]),
  })

  useEffect(() => {
    reset({
      sex: profile?.sex ?? undefined,
      title: profile?.title ?? undefined,
      firstname: profile?.firstname ?? undefined,
      lastname: profile?.lastname ?? undefined,
      birthdate: profile?.birthdate ?? undefined,
    })
  }, [profile, reset])

  const onSubmit = handleSubmit(async (profileinput) => {
    await updateProfile({
      variables: { profile: profileinput },
      onCompleted: ({ updateProfile }) => {
        if (updateProfile.authError) {
          setError('root', {
            message: authErrorToMessage(updateProfile.authError, t),
          })
        } else if (updateProfile.profileErrors) {
          const errors: string[] = []
          updateProfile.profileErrors.forEach((error) => {
            switch (error) {
              case ProfileError.InvalidBirthdateFormat:
                setError('birthdate', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidFirstnameFormat:
                setError('firstname', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidLastnameFormat:
                setError('lastname', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidTitleFormat:
                setError('title', {
                  message: profileErrorToMessage(error, t),
                })
                break
              default:
                errors.push(profileErrorToMessage(error, t))
                break
            }
            if (errors.length) {
              setError('root', {
                message: errors.join(' | '),
              })
            }
          })
        } else {
          setEditProfile(false)
          reset()
        }
      },
      onError: () => {
        setError('root', {
          message: t(
            'Saving not possible. If this error occurs repeatedly, please contact our support.'
          ),
        })
      },
    })
  })

  return (
    <div {...divArgs}>
      <h3>Person</h3>

      {errors.root?.message && (
        <AlertMessage
          type="warning"
          message={errors.root.message}
          onClose={() => clearErrors('root')}
        />
      )}
      {isSubmitSuccessful && !isDirty && (
        <AlertMessage
          type="success"
          message={t('Profile successfully saved.')}
          onClose={() => reset()}
        />
      )}

      <form onSubmit={onSubmit}>
        <fieldset className="my-[13px]">
          <RadioGroup
            options={[
              { value: ProfileSex.Female, title: t('Mrs.') },
              { value: ProfileSex.Male, title: t('Mr.') },
            ]}
            className="mb-6"
            disabled={isSubmitting || !editProfile}
            {...register('sex', { required: true })}
          />

          <label htmlFor="title" className="invisible">
            {t('Title')}
          </label>
          <input
            className={classNames(
              editProfile ? 'active_input' : 'input',
              errors.title && '!border-red-600 !border-2'
            )}
            disabled={isSubmitting || !editProfile}
            type="text"
            placeholder={t('Title')}
            {...register('title')}
          />
          <label htmlFor="firstname" className="invisible">
            {t('First name')}
          </label>
          <input
            className={classNames(
              editProfile ? 'active_input' : 'input',
              errors.firstname && '!border-red-600 !border-2'
            )}
            disabled={isSubmitting || !editProfile}
            type="text"
            placeholder={t('First name')}
            aria-invalid={errors.firstname ? 'true' : 'false'}
            {...register('firstname', {
              required: t('This field cannot be empty.'),
              pattern: {
                value: firstnameRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidFirstnameFormat,
                  t
                ),
              },
            })}
          />
          {errors.firstname && (
            <label
              htmlFor="lastname"
              className="text-red-600 flex justify-end text-right"
            >
              {errors.firstname?.message || t('This field cannot be empty.')}
            </label>
          )}
          <label htmlFor="lastname" className="invisible">
            {t('Last name')}
          </label>
          <input
            className={classNames(
              editProfile ? 'active_input' : 'input',
              errors.lastname && '!border-red-600 !border-2'
            )}
            disabled={isSubmitting || !editProfile}
            type="text"
            placeholder={t('Last name')}
            {...register('lastname', {
              required: t('This field cannot be empty.'),
              pattern: {
                value: lastnameRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidLastnameFormat,
                  t
                ),
              },
            })}
          />
          {errors.lastname && (
            <label
              htmlFor="lastname"
              className="text-red-600 flex justify-end text-right"
            >
              {errors.lastname?.message || t('This field cannot be empty.')}
            </label>
          )}
          <label htmlFor="birthdate" className="invisible">
            {t('Date of birth')}
          </label>
          <input
            className={classNames(
              editProfile ? 'active_input' : 'input',
              errors.birthdate && '!border-red-600 !border-2'
            )}
            disabled={isSubmitting || !editProfile}
            type="date"
            placeholder={t('Date of birth')}
            {...register('birthdate', {
              required: t('This field cannot be empty.'),
              pattern: {
                value: birthdateRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidBirthdateFormat,
                  t
                ),
              },
              validate: (date) => {
                return DateTime.fromISO(date) <
                  DateTime.now().minus(Duration.fromObject({ years: 18 }))
                  ? undefined
                  : profileErrorToMessage(
                      ProfileError.InvalidBirthdateFormat,
                      t
                    )
              },
            })}
          />
          {errors.birthdate && (
            <label
              htmlFor="birthdate"
              className="text-red-600 flex justify-end text-right"
            >
              {errors.birthdate?.message || t('This field cannot be empty.')}
            </label>
          )}

          {editProfile && (
            <button
              type="submit"
              className="mr-4 button-primary button-m"
              disabled={isSubmitting || !isDirty}
            >
              {isSubmitting ? 'Speichert...' : 'Daten ändern'}
            </button>
          )}
          <button
            type="button"
            className={classNames(
              'button-m mt-3',
              editProfile ? 'button-secondary' : 'button-primary'
            )}
            onClick={() => {
              reset()
              setEditProfile(!editProfile)
            }}
          >
            {editProfile ? 'Abbrechen' : 'Bearbeiten'}
          </button>
        </fieldset>
      </form>
    </div>
  )
}

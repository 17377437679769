import classNames from 'classnames'
import markdownIt from 'markdown-it'
import './MdRenderer.scss'

interface Props {
  mdText: string
  styling?: boolean
  className?: string
}

const mdi = markdownIt()

const defaultRender =
  mdi.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options)
  }
mdi.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex('target')

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']) // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank' // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self)
}

export const MdRenderer: React.FC<Props> = ({
  mdText,
  styling = false,
  className,
}) => {
  return (
    <div
      className={classNames('h-full', className, styling && 'report')}
      dangerouslySetInnerHTML={{ __html: mdi.render(mdText) }}
    />
  )
}

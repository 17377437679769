import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { DateTime, Duration } from 'luxon'
import type { FC } from 'react'
import { useContext, useEffect, useMemo } from 'react'
import { useA11yDialog } from 'react-a11y-dialog'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  ProfileInput,
  RegisterUserProfileAndSendPhoneVerificationMutation,
  RegisterUserProfileAndSendPhoneVerificationMutationVariables,
  UserFragment,
} from '../../GraphQL/graphql'
import {
  ProfileError,
  ProfileSex,
  RegisterUserProfileAndSendPhoneVerificationDocument,
} from '../../GraphQL/graphql'
import {
  authErrorToMessage,
  birthdateRegex,
  firstnameRegex,
  lastnameRegex,
  phoneRegex,
} from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { AlertMessage } from '../AlertMessage/AlertMessage'
import { Button } from '../Button'
import { RadioGroup } from '../RadioGroup/RadioGroup'

type Props = {
  isDisabled?: boolean
  smallPaddingBottom?: boolean
  profile?: UserFragment
}

export const RegisterEnterProfileInfo: FC<Props> = ({
  isDisabled,
  smallPaddingBottom,
  profile,
}) => {
  const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)

  const [registerProfile, { loading }] = useMutation<
    RegisterUserProfileAndSendPhoneVerificationMutation,
    RegisterUserProfileAndSendPhoneVerificationMutationVariables
  >(RegisterUserProfileAndSendPhoneVerificationDocument, {
    onCompleted: ({ registerUserProfileAndSendPhoneVerification }) => {
      if (registerUserProfileAndSendPhoneVerification.authError) {
        setError('root', {
          message: authErrorToMessage(
            registerUserProfileAndSendPhoneVerification.authError,
            t
          ),
        })
      } else if (registerUserProfileAndSendPhoneVerification.profileErrors) {
        const errors: string[] = []
        registerUserProfileAndSendPhoneVerification.profileErrors.forEach(
          (error) => {
            switch (error) {
              case ProfileError.InvalidBirthdateFormat:
                setError('birthdate', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidFirstnameFormat:
                setError('firstname', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidLastnameFormat:
                setError('lastname', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidTitleFormat:
                setError('title', {
                  message: profileErrorToMessage(error, t),
                })
                break
              case ProfileError.InvalidPhoneFormat:
                setError('phone', {
                  message: profileErrorToMessage(error, t),
                })
                break
              default:
                errors.push(profileErrorToMessage(error, t))
                break
            }
            if (errors.length) {
              setError('root', {
                message: errors.join(' | '),
              })
            }
          }
        )
      } else if (registerUserProfileAndSendPhoneVerification.accessToken) {
        updateAuth(registerUserProfileAndSendPhoneVerification.accessToken)
      }
    },
    onError: () => {
      setError('root', {
        message: t(
          'Saving not possible. If this error occurs repeatedly, please contact our support.'
        ),
      })
    },
  })

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<ProfileInput & { phone: string }>({
    defaultValues: useMemo(() => {
      return {
        phone: profile?.pendingPhone ?? undefined,
        sex: profile?.sex ?? undefined,
        title: profile?.title ?? undefined,
        firstname: profile?.firstname ?? undefined,
        lastname: profile?.lastname ?? undefined,
        birthdate: profile?.birthdate ?? undefined,
      }
    }, [
      profile?.pendingPhone,
      profile?.sex,
      profile?.title,
      profile?.firstname,
      profile?.lastname,
      profile?.birthdate,
    ]),
  })

  useEffect(() => {
    reset({
      phone: profile?.pendingPhone ?? undefined,
      sex: profile?.sex ?? ProfileSex.Female,
      title: profile?.title ?? undefined,
      firstname: profile?.firstname ?? undefined,
      lastname: profile?.lastname ?? undefined,
      birthdate: profile?.birthdate ?? undefined,
    })
  }, [profile, reset])

  const onSubmit = handleSubmit(async (profileinput) => {
    const { phone, ...profile } = profileinput
    await registerProfile({
      variables: {
        profile,
        phone,
      },
    })
  })

  const [instance, attr] = useA11yDialog({
    id: 'why-those-two-dialog',
    title: 'Wer ist DocRobin?',
  })
  const dialog = createPortal(
    <div
      {...attr.container}
      className="dialog-container h-fit max-w-[36.25rem] my-auto mx-auto"
    >
      <div {...attr.overlay} className="dialog-overlay" />

      <div {...attr.dialog} className="dialog-content p-4 overflow-auto">
        <p className="mb-4 text-lg">
          Wenn wir Missbrauch befürchten, senden Ihnen eine SMS an Ihre
          Handynummer, um Sie beim Login sicher zu identifizieren und so Ihre
          Daten zu schützen. Mit dieser Kombination von Passwort und SMS folgen
          wir der Empfehlung für 2-Faktor-Authentisierung des Bundesamts für
          Sicherheit in der Informationstechnik (BSI).
        </p>
        <div className="flex justify-end">
          <Button
            onClick={() => instance.hide()}
            variant="secondary"
            className="px-16 text-lg"
            outline
          >
            Ok
          </Button>
        </div>
      </div>
    </div>,
    document.body
  )

  return (
    <>
      <div>
        {errors.root?.message && (
          <div className="mb-10">
            <AlertMessage
              type="warning"
              message={errors.root.message}
              onClose={() => clearErrors('root')}
            />
          </div>
        )}
        {isSubmitSuccessful && !isDirty && (
          <div className="mb-10">
            <AlertMessage
              type="success"
              message={t('Profile successfully saved.')}
            />
          </div>
        )}
        <p>
          {t(
            'These statements form the basis for your consultation with your doctor.'
          )}
        </p>
        <form onSubmit={onSubmit}>
          <fieldset className="my-[13px] flex flex-col">
            <RadioGroup
              options={[
                { value: ProfileSex.Female, title: t('Mrs.') },
                { value: ProfileSex.Male, title: t('Mr.') },
              ]}
              className="mb-6"
              disabled={isSubmitting || isDisabled}
              {...register('sex', { required: true })}
            />
            <div className="mb-6">
              <label htmlFor="firstname" className="invisible hidden">
                {t('First name')}
              </label>
              <input
                className={classNames(
                  'input',
                  errors.firstname && '!border-red-600 !border-2'
                )}
                disabled={isSubmitting || isDisabled}
                type="text"
                placeholder={t('First name')}
                aria-invalid={errors.firstname ? 'true' : 'false'}
                {...register('firstname', {
                  required: t('This field cannot be empty.'),
                  pattern: {
                    value: firstnameRegex,
                    message: profileErrorToMessage(
                      ProfileError.InvalidFirstnameFormat,
                      t
                    ),
                  },
                })}
              />
              {errors.firstname && (
                <label
                  htmlFor="lastname"
                  className="text-red-600 flex justify-end text-right"
                >
                  {errors.firstname?.message ||
                    t('This field cannot be empty.')}
                </label>
              )}
            </div>
            <div className="mb-6">
              <label htmlFor="lastname" className="invisible hidden">
                {t('Last name')}
              </label>
              <input
                className={classNames(
                  'input',
                  errors.lastname && '!border-red-600 !border-2'
                )}
                disabled={isSubmitting || isDisabled}
                type="text"
                placeholder={t('Last name')}
                {...register('lastname', {
                  required: t('This field cannot be empty.'),
                  pattern: {
                    value: lastnameRegex,
                    message: profileErrorToMessage(
                      ProfileError.InvalidLastnameFormat,
                      t
                    ),
                  },
                })}
              />
              {errors.lastname && (
                <label
                  htmlFor="lastname"
                  className="text-red-600 flex justify-end text-right"
                >
                  {errors.lastname?.message || t('This field cannot be empty.')}
                </label>
              )}
            </div>
            <div className="mb-6">
              <label className="mb-2 inline-block" htmlFor="birthdate">
                {t('Date of birth')}
              </label>
              <input
                className={classNames(
                  'input',
                  errors.birthdate && '!border-red-600 !border-2'
                )}
                disabled={isSubmitting || isDisabled}
                type="date"
                placeholder={t('Date of birth')}
                {...register('birthdate', {
                  required: t('This field cannot be empty.'),
                  pattern: {
                    value: birthdateRegex,
                    message: profileErrorToMessage(
                      ProfileError.InvalidBirthdateFormat,
                      t
                    ),
                  },
                  validate: (date) => {
                    return DateTime.fromISO(date) <
                      DateTime.now().minus(Duration.fromObject({ years: 18 }))
                      ? undefined
                      : profileErrorToMessage(
                          ProfileError.InvalidBirthdateFormat,
                          t
                        )
                  },
                })}
              />
              {errors.birthdate && (
                <label
                  htmlFor="birthdate"
                  className="text-red-600 flex justify-end text-right"
                >
                  {errors.birthdate?.message ||
                    t('This field cannot be empty.')}
                </label>
              )}
            </div>

            <div>
              <div className="mb-2">
                <p>{t('Protect your data!')}</p>
                <a
                  href="#"
                  onClick={() => instance.show()}
                  className="underline"
                  rel="noreferrer"
                >
                  {t('Why provide the mobile phone number?')}
                </a>
              </div>
              <label htmlFor="phone" className="invisible hidden">
                {t('Phone')}
              </label>
              <input
                className={classNames(
                  'input mb-2',
                  errors.phone && '!border-red-600 !border-2'
                )}
                disabled={isSubmitting || isDisabled}
                type="text"
                placeholder={t('Phone')}
                {...register('phone', {
                  required: t('This field cannot be empty.'),
                  pattern: {
                    value: phoneRegex,
                    message: profileErrorToMessage(
                      ProfileError.InvalidPhoneFormat,
                      t
                    ),
                  },
                })}
              />
              {!errors.phone && (
                <label htmlFor="phone">
                  {t('With country code, e.g. +10123456789')}
                </label>
              )}
              {errors.phone?.message && (
                <label
                  htmlFor="phone"
                  className="text-red-600 flex justify-end"
                >
                  {errors.phone.message}
                </label>
              )}
            </div>
            {!isDisabled && (
              <button
                disabled={!isDirty}
                type="submit"
                className={classNames('button-primary button-l', {
                  'mt-8 lg:mt-[64px]': !smallPaddingBottom,
                  'mt-[42px]': !!smallPaddingBottom,
                })}
              >
                {loading ? t('Loading...') : t('Continue doctor talk')}
              </button>
            )}
          </fieldset>
        </form>
      </div>
      {dialog}
    </>
  )
}

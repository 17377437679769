import { useLazyQuery, useMutation } from '@apollo/client'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertMessage } from '../../Components/AlertMessage/AlertMessage'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import type {
  GetUserFromPasswordRefreshTokenQuery,
  GetUserFromPasswordRefreshTokenQueryVariables,
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from '../../GraphQL/graphql'
import {
  GetUserFromPasswordRefreshTokenDocument,
  ProfileError,
  UpdatePasswordDocument,
} from '../../GraphQL/graphql'
import { authErrorToMessage, passwordRegex } from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'

type PasswordResetParams = {
  userId: string
  token: string
}
export const PasswordReset: React.FC = () => {
  const { t } = useTranslation()
  const { userId, token } = useParams<PasswordResetParams>()

  const [getUser, { data: userData, loading: userLoading, error: userError }] =
    useLazyQuery<
      GetUserFromPasswordRefreshTokenQuery,
      GetUserFromPasswordRefreshTokenQueryVariables
    >(GetUserFromPasswordRefreshTokenDocument)

  useEffect(() => {
    if (userId && token) {
      getUser({ variables: { userId, token } })
    }
  }, [getUser, token, userId])

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm<{ password: string; passwordRepeat: string }>({
    defaultValues: {
      password: undefined,
      passwordRepeat: undefined,
    },
  })

  const navigate = useNavigate()

  const [updatePassword] = useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UpdatePasswordDocument, { fetchPolicy: 'network-only' })

  const onSubmit = handleSubmit(async ({ password, passwordRepeat }) => {
    if (password !== passwordRepeat) {
      setError('passwordRepeat', {
        message: t('The password repetition must match the new password.'),
      })
      return
    }
    if (!userId || !token) {
      setError('root', {
        message: t('An error has occurred. Please try to reload the page.'),
      })
      return
    }
    await updatePassword({
      variables: {
        password,
        userId,
        token,
      },
      onCompleted: ({ updatePassword }) => {
        if (updatePassword.authError) {
          setError('root', {
            message: authErrorToMessage(updatePassword.authError, t),
          })
        } else if (updatePassword.profileErrors) {
          const errors: string[] = []
          updatePassword.profileErrors.forEach((error) => {
            switch (error) {
              case ProfileError.InvalidPasswordFormat:
                setError('password', {
                  message: profileErrorToMessage(error, t),
                })
                break
              default:
                errors.push(profileErrorToMessage(error, t))
                break
            }
            if (errors.length) {
              setError('root', {
                message: errors.join(' | '),
              })
            }
          })
        } else if (!updatePassword.success) {
          setError('root', {
            message: t(
              'Saving not possible. If this error occurs repeatedly, please contact our support.'
            ),
          })
        } else {
          navigate('/login')
        }
      },
      onError: () =>
        setError('root', {
          message: t(
            'Saving not possible. If this error occurs repeatedly, please contact our support.'
          ),
        }),
    })
  })

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]}>
      <div className="flex flex-col min-[650px]:w-[555px] mx-auto">
        <h1 className="color-3 text-[64px]/[72px] text-center">
          {t('Reset password')}
        </h1>
        {userLoading && <Spinner />}
        {!userData?.getUserFromPasswordRefreshToken && userError && (
          <AlertMessage type="warning" message={t('Could not load user.')} />
        )}
        {userData?.getUserFromPasswordRefreshToken.error && (
          <AlertMessage
            type="warning"
            message={authErrorToMessage(
              userData.getUserFromPasswordRefreshToken.error,
              t
            )}
          />
        )}
        {userData?.getUserFromPasswordRefreshToken.email && (
          <>
            <h2 className="color-3 text-center">
              {t('Change password of user {{email}}.', {
                email: userData.getUserFromPasswordRefreshToken.email,
              })}
            </h2>
            <div className="flex flex-col mb-[50px]">
              {errors.root?.message && (
                <AlertMessage
                  type="warning"
                  message={errors.root.message}
                  onClose={() => clearErrors('root')}
                />
              )}
              {isSubmitSuccessful && !isDirty && (
                <AlertMessage
                  type="success"
                  message={t('Profile successfully saved.')}
                />
              )}
              <form onSubmit={onSubmit}>
                <label htmlFor="password" className="invisible">
                  {t('New password')}
                </label>
                <input
                  className={classNames(
                    'input',
                    errors.password && '!border-red-600 !border-2'
                  )}
                  disabled={isSubmitting}
                  type="password"
                  placeholder={t('New password')}
                  {...register('password', {
                    required: t('This field cannot be empty.'),
                    pattern: {
                      value: passwordRegex,
                      message: profileErrorToMessage(
                        ProfileError.InvalidPasswordFormat,
                        t
                      ),
                    },
                  })}
                />
                {errors.password?.message && (
                  <label
                    htmlFor="password"
                    className="text-red-600 flex justify-end text-right"
                  >
                    {errors.password.message}
                  </label>
                )}

                <label htmlFor="passwordRepeat" className="invisible">
                  {t('Repeat password')}
                </label>
                <input
                  className="input"
                  disabled={isSubmitting}
                  type="password"
                  placeholder={t('Repeat password')}
                  {...register('passwordRepeat', {
                    required: t('This field cannot be empty.'),
                    validate: (passwordRepeat) => {
                      return getValues('password') !== passwordRepeat
                        ? t(
                            'The password repetition must match the new password.'
                          )
                        : undefined
                    },
                  })}
                />
                {errors.passwordRepeat?.message && (
                  <label
                    htmlFor="passwordRepeat"
                    className="text-red-600 flex justify-end text-right"
                  >
                    {errors.passwordRepeat.message}
                  </label>
                )}
                <button
                  disabled={isSubmitting || !isDirty || userLoading}
                  type="submit"
                  className="button-primary button-l mt-8 lg:mt-[64px]"
                >
                  {isSubmitting ? t('Saving…') : t('Save')}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </Shell>
  )
}

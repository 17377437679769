import { useMutation } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/20/solid'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type {
  ActiveSessionFragment,
  ContinueSessionMutation,
  ContinueSessionMutationVariables,
  OptionObject,
} from '../../GraphQL/graphql'
import {
  ContinueSessionDocument,
  ConversationError,
  FreeType,
} from '../../GraphQL/graphql'
import type { UseSessionStateType } from '../../Hooks/useActiveSession'
import { toggleElementInArray } from '../../Utils/ArrayUtils'
import { Button } from '../Button'
import { SendInputButton } from '../SendInputButton/SendInputButton'

interface Props {
  activeSession: ActiveSessionFragment
  useSessionState?: UseSessionStateType
  onAnswerCallback?: () => void
}

export const ChatInputInner: React.FC<Props> = ({
  activeSession: { error, input, session },
  useSessionState,
  onAnswerCallback,
}) => {
  const { t } = useTranslation()
  const freeInputRef = useRef<HTMLInputElement>(null)

  const [sessionState, setSessionState] = useSessionState || []
  const [selectedElements, setSelectedElements] = useState<OptionObject[]>([])
  const [userInput, setUserInput] = useState('')

  const [continueSession] = useMutation<
    ContinueSessionMutation,
    ContinueSessionMutationVariables
  >(ContinueSessionDocument, {
    onCompleted: (data) =>
      setSessionState &&
      data.continueSession.error &&
      setSessionState({
        loading: false,
        error: data.continueSession.error ?? undefined,
      }),
  })

  useEffect(() => {
    input?.freeType && freeInputRef.current?.focus()
  }, [input])

  const continueConversation = useCallback(
    async ({
      responseOptionIds,
      responseText,
    }: {
      responseOptionIds?: ContinueSessionMutationVariables['responseOptionIds']
      responseText?: ContinueSessionMutationVariables['responseText']
    }) => {
      if (!session) return
      setSessionState && setSessionState({ loading: true })

      await continueSession({
        variables: {
          sessionId: session.id,
          lastElementId: session.messages?.at(-1)?.treeElementId,
          responseOptionIds,
          responseText,
        },
      })

      setSelectedElements([])
      setUserInput('')

      if (onAnswerCallback) {
        onAnswerCallback()
      }
    },
    [continueSession, session, setSessionState, onAnswerCallback]
  )

  if (!session || error?.error === ConversationError.NoNext) return null

  if (!input) {
    return (
      <div className="flex-1 self-end">
        <Button
          size="md"
          className="inline-block mr-2 mt-1 mb-1"
          onClick={() => {
            continueConversation({})
          }}
        >
          {t('Continue')}
        </Button>
      </div>
    )
  }

  const submitInputConversation = () => {
    if (selectedElements.length === 0 && userInput === '') {
      return
    }

    continueConversation({
      responseText: userInput,
      responseOptionIds: selectedElements.map(({ id }) => id),
    })
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      submitInputConversation()
    }
  }

  if (sessionState?.error || sessionState?.loading) return <>{}</>

  return (
    <>
      {input.options && (
        <div className="flex-1 self-end">
          {input.options.map((optionGroup) => {
            const isOptionGroupMultiSelect = optionGroup.length > 1

            return isOptionGroupMultiSelect ? (
              <div key={optionGroup.at(0)?.id} className="space-y-3">
                <fieldset className="flex flex-wrap gap-4 -mx-5 sm:max-w-xl sm:mx-0 sm:divide-y-0">
                  {optionGroup.map((element) => (
                    <label
                      key={element.id}
                      aria-checked={selectedElements.some(
                        ({ id }) => id === element.id
                      )}
                      className="relative flex items-center gap-3 px-5 py-3 font-medium text-gray-900 sm:py-2 sm:px-0 group aria-checked:sm:bg-transparent"
                      onClick={() => {
                        let elements = selectedElements.filter((e) =>
                          optionGroup.includes(e)
                        )

                        elements = toggleElementInArray(elements, element)

                        if (elements.length) {
                          setUserInput('')
                        }
                        setSelectedElements(elements)
                      }}
                    >
                      <div className="bg-white border-2 border-gray-300 rounded-md group-aria-checked:bg-dro-green group-aria-checked:border-dro-green">
                        <CheckIcon className="invisible w-5 h-5 text-white group-aria-checked:visible" />
                      </div>
                      <div className="flex-1 min-w-0 text-left">
                        {element.text}
                      </div>
                    </label>
                  ))}
                </fieldset>
                <Button
                  className="inline-block mr-2 mt-1 mb-1"
                  size="md"
                  disabled={!selectedElements.length}
                  onClick={() => {
                    if (!selectedElements.length) return

                    continueConversation({
                      responseText: userInput,
                      responseOptionIds: selectedElements.map(({ id }) => id),
                    })
                  }}
                >
                  {t('Confirm selection')}
                </Button>
              </div>
            ) : (
              optionGroup.map((element) => (
                <Button
                  key={element.id}
                  size="md"
                  className="inline-block mr-2 mt-1 mb-1"
                  disabled={!!selectedElements.length}
                  onClick={() => {
                    continueConversation({
                      responseOptionIds: [element.id],
                    })
                  }}
                >
                  {element.text}
                </Button>
              ))
            )
          })}
        </div>
      )}

      {input.freeType && (
        <div className="flex-1 mr-4">
          <input
            ref={freeInputRef}
            className="old-input"
            disabled={selectedElements.length > 0}
            type={
              [FreeType.Decimal, FreeType.Int].includes(input.freeType)
                ? 'number'
                : FreeType.Date === input.freeType
                ? 'date'
                : 'text'
            }
            onChange={(e) => setUserInput(e.target.value)}
            step={
              FreeType.Decimal === input.freeType
                ? '0.01'
                : FreeType.Int === input.freeType
                ? '1'
                : undefined
            }
            value={selectedElements.length === 0 ? userInput : ''}
            onKeyUp={onKeyUp}
          />
        </div>
      )}
      {input.freeType && (
        <div className="grow-0 shrink-0 flex items-center">
          <SendInputButton
            disabled={selectedElements.length === 0 && userInput === ''}
            onClick={submitInputConversation}
          />
        </div>
      )}
    </>
  )
}

import { MdRenderer } from '../MdRenderer/MdRenderer'
import { ReportLayout } from '../ReportLayout/ReportLayout'
import { Spinner } from '../Spinner/Spinner'

interface Props {
  // TODO: delete `| null` after backend sends only `undefined`
  data?: string | null
  loading: boolean
}

export const AnamnesisContent: React.FC<Props> = ({ data, loading }) => {
  return (
    <div>
      {loading && <Spinner withLayout={true} />}
      {!loading && data && (
        <ReportLayout title="Anamnese-Bericht">
          <MdRenderer mdText={data} styling />
        </ReportLayout>
      )}
    </div>
  )
}

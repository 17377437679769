import classNames from 'classnames'
import type { FC, HTMLAttributes } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AuthError,
  ProfileError,
  type UpdatePhoneMutationVariables,
  type VerifyUserPhoneMutationVariables,
} from '../../GraphQL/graphql'
import {
  authErrorToMessage,
  factorRegex,
  phoneRegex,
} from '../../Helper/AuthHelper'
import { profileErrorToMessage } from '../../Helper/UserHelper'
import { useProfile } from '../../Hooks/useProfile'
import { AlertMessage } from '../AlertMessage/AlertMessage'

enum ProfileUpdatePhoneState {
  View,
  EditPhone,
  VerifyPhone,
}

export const ProfileUpdatePhone: FC<HTMLAttributes<HTMLDivElement>> = ({
  ...divArgs
}) => {
  const { t } = useTranslation()

  const [state, setState] = useState(ProfileUpdatePhoneState.View)

  const {
    profile,
    mutations: {
      updatePhone: [updatePhone],
      // verifyUserPhone: [verifyUserPhone],
    },
  } = useProfile()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setFocus,
    clearErrors,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm<UpdatePhoneMutationVariables & VerifyUserPhoneMutationVariables>({
    defaultValues: useMemo(() => {
      return { phone: profile?.phone ?? undefined }
    }, [profile?.phone]),
  })

  useEffect(() => {
    reset({ phone: profile?.phone ?? undefined })
  }, [profile?.phone, reset])

  useEffect(() => {
    state === ProfileUpdatePhoneState.VerifyPhone && setFocus('smsToken')
  }, [setFocus, state])

  const onSubmit = handleSubmit(async ({ phone, smsToken }) => {
    if (state === ProfileUpdatePhoneState.EditPhone || !smsToken) {
      await updatePhone({
        variables: { phone },
        onCompleted: ({ updatePhone }) => {
          if (updatePhone.authError) {
            setError('root', {
              message: authErrorToMessage(updatePhone.authError, t),
            })
          } else if (updatePhone.profileErrors) {
            const errors: string[] = []
            updatePhone.profileErrors.forEach((error) => {
              switch (error) {
                case ProfileError.InvalidPhoneFormat:
                  setError('phone', {
                    message: profileErrorToMessage(error, t),
                  })
                  break
                default:
                  errors.push(profileErrorToMessage(error, t))
                  break
              }
              if (errors.length) {
                setError('root', {
                  message: errors.join(' | '),
                })
              }
            })
          } else if (updatePhone.user) {
            setState(ProfileUpdatePhoneState.View)
          }
        },
        onError: () =>
          setError('root', {
            message: t(
              'Saving not possible. If this error occurs repeatedly, please contact our support.'
            ),
          }),
      })
    } else if (state === ProfileUpdatePhoneState.VerifyPhone && smsToken) {
      setState(ProfileUpdatePhoneState.View)
      // await verifyUserPhone({
      //   variables: { smsToken },
      //   onCompleted: ({ verifyUserPhone }) => {
      //     if (verifyUserPhone.error) {
      //       setError('root', {
      //         message: authErrorToMessage(verifyUserPhone.error, t),
      //       })
      //     } else if (verifyUserPhone.accessToken) {
      //       setAccessToken(verifyUserPhone.accessToken)
      //       setState(ProfileUpdatePhoneState.View)
      //       reset()
      //     }
      //   },
      //   onError: () =>
      //     setError('root', {
      //       message: t(
      //         'Saving not possible. If this error occurs repeatedly, please contact our support.'
      //       ),
      //     }),
      // })
    }
  })

  return (
    <div {...divArgs}>
      <h3>Telefonnummer</h3>

      {isSubmitSuccessful &&
        profile?.pendingPhone &&
        state === ProfileUpdatePhoneState.VerifyPhone && (
          <AlertMessage
            type="success"
            message={t(
              'Phone successfully saved. You have been sent a code to {{phone}} that you can use to activate it.',
              { phone: profile.pendingPhone }
            )}
          />
        )}

      {errors.root?.message && (
        <AlertMessage
          type="warning"
          message={errors.root.message}
          onClose={() => clearErrors('root')}
        />
      )}

      <form onSubmit={onSubmit}>
        <fieldset className="my-[13px]">
          <label htmlFor="phone" className="invisible">
            {t('Phone')}
          </label>

          <input
            className={classNames(
              state ? 'active_input' : 'input',
              errors.phone && '!border-red-600 !border-2'
            )}
            disabled={
              isSubmitting || state !== ProfileUpdatePhoneState.EditPhone
            }
            type="text"
            placeholder={t('Phone')}
            {...register('phone', {
              required: t('This field cannot be empty.'),
              pattern: {
                value: phoneRegex,
                message: profileErrorToMessage(
                  ProfileError.InvalidPhoneFormat,
                  t
                ),
              },
            })}
          />
          {!errors.phone?.message && state !== ProfileUpdatePhoneState.View && (
            <label htmlFor="phone" className="hidden">
              {t('With country code, e.g. +10123456789')}
            </label>
          )}
          {errors.phone?.message && (
            <label htmlFor="phone" className="text-red-600 flex justify-end">
              {errors.phone.message}
            </label>
          )}
          {state === ProfileUpdatePhoneState.VerifyPhone && (
            <>
              <label htmlFor="smsToken" className="invisible">
                {t('E-Mail Token')}
              </label>
              <input
                className={classNames(
                  'input',
                  errors.smsToken && '!border-red-600 !border-2'
                )}
                disabled={
                  isSubmitting || state !== ProfileUpdatePhoneState.VerifyPhone
                }
                type="number"
                inputMode="numeric"
                placeholder={t('E-Mail Token')}
                {...register('smsToken', {
                  required: t('This field cannot be empty.'),
                  pattern: {
                    value: factorRegex,
                    message: authErrorToMessage(AuthError.InvalidTwoFaToken, t),
                  },
                })}
              />
              {errors.smsToken?.message && (
                <label
                  htmlFor="smsToken"
                  className="text-red-600 flex justify-end text-right"
                >
                  {errors.smsToken.message}
                </label>
              )}
            </>
          )}

          {state === ProfileUpdatePhoneState.EditPhone && (
            <button
              type="submit"
              className="mt-3 mr-3 button-primary button-m"
              disabled={isSubmitting || !isDirty}
            >
              {isSubmitting ? 'Speichert...' : 'Telefonnummer ändern'}
            </button>
          )}

          {state === ProfileUpdatePhoneState.VerifyPhone && (
            <button
              type="submit"
              className="mr-4 button-primary button-m"
              disabled={isSubmitting || !isDirty}
            >
              {isSubmitting ? 'Speichert...' : 'Telefonnummer bestätigen'}
            </button>
          )}

          <button
            type="button"
            className={classNames(
              'button-m mt-3',
              state !== ProfileUpdatePhoneState.View
                ? 'button-secondary'
                : 'button-primary'
            )}
            onClick={() => {
              reset()
              setState(
                state === ProfileUpdatePhoneState.View
                  ? ProfileUpdatePhoneState.EditPhone
                  : ProfileUpdatePhoneState.View
              )
            }}
          >
            {state !== ProfileUpdatePhoneState.View
              ? 'Abbrechen'
              : 'Bearbeiten'}
          </button>
        </fieldset>
      </form>
    </div>
  )
}

import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import { AnamnesisContent } from '../../Components/AnamnesisContent/AnamnesisContent'
import { Shell } from '../../Components/Shell/Shell'
import { useAnamnesis } from '../../Hooks/useAnamnesis'
import { Alert } from '../../Modules/Alert/Alert'
import { Loading } from '../../Modules/Loading/Loading'

type AnamnesisParams = {
  sessionId?: string
}

export const AnamnesisPage: React.FC = () => {
  // const { t } = useTranslation()
  const { sessionId } = useParams<AnamnesisParams>()
  const { anamnesis, anamnesisLoading, anamnesisError } =
    useAnamnesis(sessionId)

  if (anamnesisLoading) {
    return (
      <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
        <Loading />
      </Shell>
    )
  }

  if (anamnesisError || !anamnesis) {
    return (
      <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
        <Alert message={t('Could not load report.')} />
      </Shell>
    )
  }

  return (
    <Shell preGeneratedCircles={[]} autogeneratedCircles={0}>
      {anamnesisError || !anamnesis ? (
        <div className="text-center">
          {/* {t('Anamnesis could not be generated.')} */}
        </div>
      ) : (
        <AnamnesisContent data={anamnesis} loading={anamnesisLoading} />
      )}
    </Shell>
  )
}

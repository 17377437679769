import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type {
  DeleteDicomSeriesMutation,
  DicomPatientFragment,
} from '../../GraphQL/graphql'
import { DeleteDicomSeriesDocument } from '../../GraphQL/graphql'
import { urlSeriesRendered } from '../../Helper/DicomHelper'
import { Spinner } from '../Spinner/Spinner'

interface Props {
  dicomPatient: DicomPatientFragment
  // reports?: BaseReportFragment[] | null
}

export const DicomFileList: React.FC<Props> = ({ dicomPatient }) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const handle = async () => {
      for (const ser of dicomPatient.studies.flatMap((study) => study.series)) {
        // newSrcs[ser.id] = await urlSeriesThumbnail(ser.id)
        try {
          urlSeriesRendered(ser.id, 300)
            .then((src) => {
              const image = document.getElementById(`thumbnail-${ser.id}`)
              if (image) {
                image.setAttribute('src', src)
              }
            })
            .catch(() => {
              // TODO: handle error properly
            })
            .finally(() => {
              const spinner = document.getElementById(`spinner-${ser.id}`)
              const image = document.getElementById(`thumbnail-${ser.id}`)
              if (spinner) {
                spinner.hidden = true
              }
              if (image) {
                image.hidden = false
              }
            })
        } catch (exception) {
          const image = document.getElementById(`thumbnail-${ser.id}`)
          const spinner = document.getElementById(`spinner-${ser.id}`)
          if (spinner) {
            spinner.hidden = true
          }
          if (image) {
            image.hidden = false
          }
        }
      }
    }
    handle()
  }, [dicomPatient])

  // const [getRenderedInstance, {data:imgData, loading: imgLoading}] = useLazyQuery<GetRenderedDicomInstanceQuery>(GetRenderedDicomInstanceDocument)
  const [deleteDicomSeries] = useMutation<DeleteDicomSeriesMutation>(
    DeleteDicomSeriesDocument
  )

  return (
    <div className="grid gap-4 2xl:gap-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mb-4">
      {dicomPatient?.studies.map((study) =>
        study.series.map((series) => {
          return (
            <div
              className="bg-dro-lightblue border rounded-4xl py-10 2xl:py-12 px-7 2xl:px-8 flex flex-col justify-between gap-6 shadow"
              key={series.id}
            >
              <div>
                <div className="font-noto-serif font-bold text-3xl 2xl:text-4xl text-white mb-4">
                  {study.description && <>{study.description}</>}
                </div>
                <div className="font-noto-serif font-bold text-xl 2xl:text-2xl text-white mb-4">
                  {series.description && <>{series.description}</>}
                </div>
                <div className="text-white text-xl 2xl:text-2xl font-medium">
                  {t('{{count}} images', { count: series.instances.length })}
                </div>
                {study.date && (
                  <div className="text-white text-xl 2xl:text-2xl font-medium">
                    {t('Date of study')}:{' '}
                    {new Date(study.date).toLocaleDateString(i18n.language)}
                  </div>
                )}
                {study.institution && (
                  <div className="text-white text-xl 2xl:text-2xl font-medium break-words">
                    {study.institution}
                  </div>
                )}
                <div className="m-auto grow-0 shrink-0 basis-1/5 mt-5 max-h-80 h-full">
                  <div id={`spinner-${series.id}`}>
                    <Spinner />
                  </div>
                  <img
                    hidden={true}
                    id={`thumbnail-${series.id}`}
                    className="object-contain mx-auto h-full w-full"
                    alt="Error Loading"
                  />
                </div>
              </div>
              <div>
                {series.deletable && (
                  <button
                    className="button-secondary mt-2 py-2 px-4 !text-white !border-white hover:!border-dro-blue"
                    onClick={() => {
                      deleteDicomSeries({
                        variables: { seriesId: series.id },
                      })
                    }}
                  >
                    {t('Delete')}
                  </button>
                )}
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLanguageCode } from './Helper/LanguageHelper'
import de_DE from './locales/de/translation.json'
import en from './locales/en/translation.json'

export const fallbackLng = 'de_DE'

export const resources = {
  en_GB: {
    translation: en,
  },
  en_US: {
    translation: en,
  },
  de_DE: {
    translation: de_DE,
  },
} as const

use(initReactI18next).init({
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  fallbackLng,
  resources,
  lng: getLanguageCode(),

  interpolation: {
    escapeValue: false,
  },
})

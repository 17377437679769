import { type BaseReportFragment } from '../../GraphQL/graphql'
import { sortReports } from '../../Helper/ReportHelper'
import { ReportCard } from '../ReportCard'

interface Props {
  reports?: BaseReportFragment[] | null
}

export const ReportList: React.FC<Props> = ({ reports }) => {
  return (
    <div>
      <div className="grid gap-4 2xl:gap-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        {reports &&
          [...reports]
            .sort(sortReports)
            .map((report) => (
              <ReportCard report={report} key={`report-${report.id}`} />
            ))}
      </div>
    </div>
  )
}

import { useMutation } from '@apollo/client'
import { useRef } from 'react'
import { A11yDialog } from 'react-a11y-dialog'
import type A11yDialogInstance from 'react-a11y-dialog/dist/index'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Button } from '../../Components/Button'
import { DicomFileList } from '../../Components/DicomFileList/DicomFileList'
import { DicomUpload } from '../../Components/DicomUpload/DicomUpload'
import { Shell } from '../../Components/Shell/Shell'
import { Spinner } from '../../Components/Spinner/Spinner'
import type {
  RequestEvaluationReportMutation,
  RequestEvaluationReportMutationVariables,
} from '../../GraphQL/graphql'
import {
  EvaluationQuestion,
  RequestEvaluationReportDocument,
} from '../../GraphQL/graphql'
import { DIALOG_SIZE, getDialogOptions } from '../../Helper/DialogHelper'
import { useDicomPatient } from '../../Hooks/useDicomPatient'
import { Alert } from '../../Modules/Alert/Alert'

export const Files: React.FC = () => {
  const { t } = useTranslation()
  const uploadDialogRef = useRef<A11yDialogInstance>(null)
  const successDialogRef = useRef<A11yDialogInstance>(null)
  const warnDialogRef = useRef<A11yDialogInstance>(null)

  const { dicomPatient, dicomPatientLoading } = useDicomPatient()

  const [requestReport] = useMutation<
    RequestEvaluationReportMutation,
    RequestEvaluationReportMutationVariables
  >(RequestEvaluationReportDocument)

  const [searchParams] = useSearchParams()
  const back = searchParams.get('next') || null

  const handleRequestReport = async () => {
    const res = await requestReport({
      variables: {
        question: EvaluationQuestion.KneeOsteoarthrosis,
      },
    })
    if (res.data?.requestEvaluationReport.success && !res.errors) {
      uploadDialogRef.current?.hide()
      successDialogRef.current?.show()
    } else {
      alert(
        t('An error has occurred. Please get in touch with our support team.')
      )
    }
  }

  return (
    <Shell autogeneratedCircles={0} preGeneratedCircles={[]} noLayout>
      <div>
        <h1 className="font-noto-serif text-4xl text-dro-blue font-medium">
          {t('X-ray images')}
        </h1>

        <p className="my-5">
          <Button onClick={() => uploadDialogRef.current.show()}>
            {t('Upload new x-ray images')}
          </Button>
        </p>

        {back !== null && (
          <p>
            <Button variant="secondary" to={back}>
              {t('Continue with collecting the second opinion')}
            </Button>
          </p>
        )}

        {dicomPatientLoading ? (
          <div>
            <Spinner />
          </div>
        ) : !dicomPatient?.studies.some((s) =>
            s.series.some((s) => s.instances.length > 0)
          ) ? (
          <Alert message={t('No files have been uploaded yet')} />
        ) : (
          <DicomFileList dicomPatient={dicomPatient} />
        )}
      </div>

      <A11yDialog
        dialogRef={(instance) => (uploadDialogRef.current = instance)}
        {...getDialogOptions('', 'dialog--upload', DIALOG_SIZE.SMALL)}
      >
        <div className="overflow-y-auto h-full w-full px-4 flex flex-col justify-center">
          <DicomUpload onFinish={() => uploadDialogRef.current?.hide()} />
        </div>
      </A11yDialog>

      <A11yDialog
        dialogRef={(instance) => (warnDialogRef.current = instance)}
        {...getDialogOptions(
          'Auswahl bestätigen',
          'dialog--warn',
          DIALOG_SIZE.SMALL
        )}
      >
        <div className="overflow-y-auto h-full w-full p-4 pr-12">
          <p>{t('confirm-isolated-xray-request')}</p>

          <button
            className="button-primary h-[5vh] px-4"
            onClick={() => {
              handleRequestReport()
            }}
          >
            {t('Request x-ray report')}
          </button>
          <button
            className="button-error px-5 py-3 ml-2"
            onClick={() => {
              warnDialogRef.current?.hide()
            }}
          >
            {t('Cancel')}
          </button>
        </div>
      </A11yDialog>

      <A11yDialog
        dialogRef={(instance) => (successDialogRef.current = instance)}
        {...getDialogOptions('Fertig.', 'dialog--success')}
      >
        <div className="overflow-y-auto h-full w-full p-4 pr-12">
          <p>{t('confirm-xray-requested')}</p>

          <button
            className="button-primary px-4 py-2"
            onClick={() => {
              warnDialogRef.current?.hide()
              successDialogRef.current?.hide()
            }}
          >
            {t('Okay')}
          </button>
        </div>
      </A11yDialog>
    </Shell>
  )
}

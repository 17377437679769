import type { TFunction } from 'i18next'
import type { ReportFragment } from '../GraphQL/graphql'
import { ReportType } from '../GraphQL/graphql'

export const sortReports = (
  a: Pick<ReportFragment, 'requestTimestamp' | 'releaseTimestamp'>,
  b: Pick<ReportFragment, 'requestTimestamp' | 'releaseTimestamp'>
): number =>
  new Date(b.releaseTimestamp || b.requestTimestamp).getTime() -
  new Date(a.releaseTimestamp || a.requestTimestamp).getTime()

export const getReportTitle = (
  t: TFunction,
  report?: Pick<ReportFragment, 'reportType' | 'illness'>
): string => {
  switch (report?.reportType) {
    case ReportType.EvaluationReport:
      return t('Orthopedic X-ray report')
    case ReportType.MedicalReport:
      return t('Second opinion {{illness}}', { illness: report.illness.name })
    default:
      return t('Report')
  }
}

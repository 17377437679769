const DEFAULTS = Object.freeze({
  id: 'dialog',
  classNames: {
    container: 'dialog-container dro',
    overlay: 'dialog-overlay',
    dialog: 'dialog-content',
    title: 'dialog-title dro-visually-hidden',
    closeButton: 'dialog-close',
  },
})

export const DIALOG_SIZE = {
  DEFAULT: 'regular',
  SMALL: 'small',
  FULL: 'full',
}

export const getDialogOptions = (
  title: string,
  id: string = DEFAULTS.id,
  size = DIALOG_SIZE.DEFAULT,
  // TODO: can `hideCloseButton` be deleted? It's not used anywhere
  _hideCloseButton = false
) => {
  const classes = Object.create(DEFAULTS.classNames)
  classes.dialog = `${classes.dialog} dialog-content--size-${size}`

  return { id, title, classNames: classes }
}

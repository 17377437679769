import { lazy, Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Register } from './Components/Register/Register'
import { ResearchNav } from './Components/ResearchNav/ResearchNav'
import { WebSocketProvider } from './Contexts/WebSocketContext'
import { AdminApplication, ResearchGroup } from './GraphQL/graphql'
import { useGtmDevelopment } from './Hooks/useGtmDevelopment'
import { useProfile } from './Hooks/useProfile'
import { DevelopmentNav } from './Modules/DevelopmentNav/DevelopmentNav'
import { Loading } from './Modules/Loading/Loading'
import { AnamnesisPage } from './Pages/Anamnesis/Anamnesis'
import { ChatArchive } from './Pages/ChatArchive/ChatArchive'
import { Chatbot } from './Pages/Chatbot/Chatbot'
import { Checkout } from './Pages/Checkout/Checkout'
import { DialogBot } from './Pages/DialogBot/DialogBot'
import { EmailVerification } from './Pages/EmailVerification/EmailVerification'
import { Files } from './Pages/Files/Files'
import { Login } from './Pages/Login/Login'
import { Logout } from './Pages/Logout/Logout'
import { ParamsNavigate } from './Pages/ParamsNavigate/ParamsNavigate'
import { PasswordReset } from './Pages/PasswordReset/PasswordReset'
import { PaymentResult } from './Pages/PaymentResult/PaymentResult'
import { Profile } from './Pages/Profile/Profile'
import { Report } from './Pages/Report/Report'
import { ReportOrders } from './Pages/ReportOrders.tsx/ReportOrders'
import { ReportPreview } from './Pages/ReportPreview/ReportPreview'
import { Reports } from './Pages/Reports/Reports'
import { ReportSharePage } from './Pages/ReportShare/ReportShare'
import { ResearchEndScreen } from './Pages/ResearchEndScreen/ResearchEndScreen'
import { TokenLogin } from './Pages/TokenLogin/TokenLogin'

// We need to load stripe only when we need it, otherwise it will be loaded on every page. It's potential privacy issue.
const Payment = lazy(() => import('./Pages/Payment/Payment'))

export const App = () => {
  useGtmDevelopment()

  const { profile, profileLoading } = useProfile()
  return (
    <WebSocketProvider>
      <div className="text-lg" id="App">
        {profileLoading ? (
          <Loading />
        ) : (
          <BrowserRouter basename={process.env.REACT_APP_BASE_PATH || ''}>
            <DevelopmentNav />
            <ResearchNav />

            <main>
              <Routes>
                {JSON.parse(
                  process.env.REACT_APP_IS_RESEARCH_INSTANCE ?? 'false'
                ) ? (
                  profile?.isComplete && profile.isResearch ? (
                    <>
                      <Route path="/logout" element={<Logout />} />
                      {profile.researchGroup === ResearchGroup.Avatar && (
                        <>
                          <Route path="/dialog" element={<DialogBot />} />
                          <Route
                            path="*"
                            element={<Navigate to={'/dialog'} />}
                          />
                        </>
                      )}
                      {profile.researchGroup === ResearchGroup.Chat && (
                        <>
                          <Route path="/chat" element={<Chatbot />} />
                          <Route path="*" element={<Navigate to={'/chat'} />} />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Route path="/login/:token" element={<TokenLogin />} />
                      <Route path="*" element={<ResearchEndScreen />} />
                    </>
                  )
                ) : (
                  <>
                    {!profile?.isComplete ? (
                      // User incomplete or logged out
                      <>
                        <Route path="/login/:token" element={<TokenLogin />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                      </>
                    ) : (
                      // User complete and logged in
                      <>
                        <Route path="/anamnesis" element={<AnamnesisPage />} />
                        <Route
                          path="/anamnesis/:sessionId"
                          element={<AnamnesisPage />}
                        />
                        <Route
                          path="/sessions/:sessionId/checkout"
                          element={<Checkout />}
                        />
                        <Route
                          path="/orders/:orderId/payment"
                          element={
                            <Suspense>
                              <Payment />
                            </Suspense>
                          }
                        />
                        <Route
                          path="/orders/:orderId/payment/result"
                          element={<PaymentResult />}
                        />
                        <Route path="/files" element={<Files />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/reports/:id" element={<Report />} />
                        <Route
                          path="/reports/:reportId/orders"
                          element={<ReportOrders />}
                        />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/logout" element={<Logout />} />
                      </>
                    )}
                    <Route path="/chat">
                      <Route index element={<Chatbot />} />
                      <Route
                        path="archive/:reportId"
                        element={<ChatArchive />}
                      />
                    </Route>
                    <Route path="/dialog" element={<DialogBot />} />
                    <Route
                      path="/reports/share/:secret"
                      element={<ReportSharePage />}
                    />
                    <Route
                      path="/verify-email/:userId/:token"
                      element={<EmailVerification />}
                    />
                    <Route
                      path="/password-reset/:userId/:token"
                      element={<PasswordReset />}
                    />
                    <Route path="*" element={<ParamsNavigate to="/chat" />} />
                    {profile?.rights.adminApplications.includes(
                      AdminApplication.ReportEditor
                    ) && (
                      <Route
                        path="/report/preview"
                        element={<ReportPreview />}
                      />
                    )}
                  </>
                )}
              </Routes>
            </main>
          </BrowserRouter>
        )}
      </div>
    </WebSocketProvider>
  )
}

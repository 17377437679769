import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import logo from '../../assets/logo-text-blue.png'
import { PRINT_PAGE_STYLE } from '../../Helper/PrintHelper'
import { useProfile } from '../../Hooks/useProfile'
import { Button } from '../Button'
import { Table } from '../Table/Table'
import './ReportLayout.scss'

interface Props {
  title: string
  children: ReactNode | string
  date?: Date
}

export const ReportLayout: React.FC<Props> = ({ title, children, date }) => {
  const [searchParams] = useSearchParams()
  const nextUrl: string | null = searchParams.get('next') || null
  const contentRef = useRef<HTMLDivElement | null>(null)
  const oldDocTitle = useRef<string | null>(null)

  const { t, i18n } = useTranslation()
  const dateLabel: string = (date || new Date()).toLocaleString(i18n.language)

  const { profile } = useProfile()

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    copyStyles: true,
    pageStyle: PRINT_PAGE_STYLE,
    fonts: [
      {
        family: 'Noto_Serif variant0',
        source:
          "url(https://docrobin.net/fonts/NotoSerif-VariableFont_wdth%2Cwght.woff2) format('woff2')",
      },
    ],
  })

  useEffect(() => {
    oldDocTitle.current = document.title
    // Some browsers use the page title for printing
    if (profile) {
      document.title = `${title}, ${profile.firstname} ${profile.lastname}, ${dateLabel}`
    }
    // Cleanup: restore old document title.
    return () => {
      if (oldDocTitle.current) {
        document.title = oldDocTitle.current
      }
    }
  })

  return (
    <div className="space-y-10">
      <header className="print:hidden sticky">
        <div className="container mx-auto flex justify-center gap-24">
          <div>
            <Button variant="primary" to={nextUrl ?? (-1 as To)}>
              {nextUrl ? t('Back') : t('Go to overview')}
            </Button>
          </div>

          <div>
            <Button
              variant="primary"
              onClick={() => {
                handlePrint()
              }}
            >
              {t('Print')}
            </Button>
          </div>
        </div>
      </header>
      <div className="max-w-3xl mx-auto px-5" ref={contentRef}>
        <section
          className="grid grid-cols-[repeat(2,1fr)]"
          style={{
            gridTemplateAreas: `"logo logo"
    "title title"
    "header-start header-end"
    "body body"
    "footer-start footer-end"`,
          }}
        >
          <div className="text-end" style={{ gridArea: 'logo' }}>
            <img
              src={logo}
              alt={t('DocRobin logo')}
              className="max-w-[240px] ml-auto"
            />
          </div>

          <div style={{ gridArea: 'title' }}>
            <h1 className="mb-6 dro-h1 text-dro-blue">{title}</h1>
          </div>
          <div style={{ gridArea: 'header-start' }}>
            <Table hasHeaderColumn={true} className="text-dro-blue">
              <tbody>
                <tr>
                  <td>{t('Patient')}</td>
                  <td>
                    {profile?.firstname && profile?.lastname && (
                      <>
                        <span>
                          {profile.firstname} {profile.lastname}
                        </span>
                        <br />
                      </>
                    )}
                    {profile?.email && (
                      <>
                        <span>{profile.email}</span>
                        <br />
                      </>
                    )}
                    {profile?.phone && (
                      <>
                        <span>{profile.phone}</span>
                        <br />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div style={{ gridArea: 'header-end' }}>
            <Table hasHeaderColumn={true} className="text-dro-blue">
              <tbody>
                <tr>
                  <td>{t('Created at')}</td>
                  <td>{dateLabel}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div
            className="border-t-dro-lightgray border-b-dro-lightgray border-t border-solid border-b print:border-black py-5 my-5"
            style={{ gridArea: 'body' }}
          >
            {children}
          </div>

          <div style={{ gridArea: 'footer-start' }}>
            <img
              className="max-w-[240px]"
              src={logo}
              alt={t('DocRobin logo')}
            />
          </div>
          <div style={{ gridArea: 'footer-end' }}>
            <p className="mt-2">
              {t('company-name')}
              <br />
              {t('company-street')} | {t('company-zip')} {t('company-city')}
              <br />
              {t('Email')}:{' '}
              <a href={`mailto:${t('company-email')}`}>{t('company-email')}</a>
              <br />
              {t('Phone')}:{' '}
              <a href={`tel:${t('company-phone')}`}>{t('company-phone')}</a>
              <br />
              {t('Website')}:{' '}
              <a href={t('company-website')}>{t('company-website')}</a>
              <br />
            </p>
            <p className="mt-2">
              {t('company-ceo')}
              <br />
              {t('company-legal-location')} | {t('company-legal-court')}:{' '}
              {t('company-legal-number')}
            </p>
          </div>
        </section>
      </div>
    </div>
  )
}

import { useMutation } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from '../../Components/Spinner/Spinner'
import { AuthContext } from '../../Contexts/AuthContext'
import type {
  LoginUserWithTokenMutation,
  LoginUserWithTokenMutationVariables,
} from '../../GraphQL/graphql'
import { LoginUserWithTokenDocument } from '../../GraphQL/graphql'

type LoginParams = {
  token?: string
}

export const TokenLogin: React.FC = () => {
  // const { t } = useTranslation()
  const { updateAuth } = useContext(AuthContext)

  const params = useParams<LoginParams>()
  const navigate = useNavigate()

  const [loginUser, { data, loading }] = useMutation<
    LoginUserWithTokenMutation,
    LoginUserWithTokenMutationVariables
  >(LoginUserWithTokenDocument, {
    async onCompleted(data) {
      await updateAuth(data?.loginUserWithToken.accessToken ?? undefined)
    },
  })

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (params.token) {
      loginUser({ variables: { token: params.token } })
    } else {
      setErrorMessage('Link ungültig.')
    }
  }, [loginUser, params])

  return (
    <div>
      <div className="container-xl py-6" style={{ textAlign: 'center' }}>
        {loading ? (
          <Spinner />
        ) : errorMessage ? (
          <>{errorMessage}</>
        ) : !params.token || data?.loginUserWithToken.error ? (
          <>Link ungültig.</>
        ) : (
          <>
            <p className="py-6">
              Willkommen bei DocRobin. Sie werden weitergeleitet...
            </p>
            <button
              className="old-button-primary"
              onClick={() => {
                navigate('/')
              }}
            >
              Unterhaltung starten
            </button>
          </>
        )}
      </div>
    </div>
  )
}

import classNames from 'classnames'
import type { FC } from 'react'
import type { To } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger'
  outline?: boolean
  size?: 'sm' | 'md' | 'lg'
  to?: To
}

export const Button: FC<ButtonProps> = ({
  children,
  variant = 'primary',
  outline = false,
  size = 'sm',
  to,
  className,
  ...props
}) => {
  const navigate = useNavigate()
  return (
    <button
      onClick={() => to && navigate(to)}
      className={classNames(
        'rounded-full disabled:cursor-not-allowed',
        variant === 'primary' &&
          !outline &&
          'bg-dro-green border-dro-green text-white enabled:hover:bg-dro-darkgreen enabled:hover:border-dro-darkgreen enabled:hover:text-white disabled:grayscale',
        variant === 'secondary' &&
          !outline &&
          'bg-dro-blue border-dro-blue text-white enabled:hover:bg-dro-lightblue enabled:hover:border-dro-lightblue enabled:hover:text-white disabled:grayscale ',
        variant === 'tertiary' &&
          !outline &&
          'bg-dro-lightgreen border-dro-lightgreen text-dro-green enabled:hover:bg-white enabled:hover:border-dro-green enabled:hover:text-green disabled:grayscale',
        variant === 'primary' &&
          outline &&
          'bg-white text-dro-green border-dro-green enabled:hover:bg-dro-green enabled:hover:text-white disabled:grayscale',
        variant === 'secondary' &&
          outline &&
          'bg-white text-dro-blue border-dro-blue enabled:hover:bg-dro-blue enabled:hover:text-white disabled:grayscale',

        size === 'sm' && 'py-1.5 px-5 border',
        size === 'md' && 'py-3 px-8 border',
        size === 'lg' && 'py-5 px-8 border text-2xl font-medium',
        variant === 'danger' &&
          'bg-red-600 text-white border-red-800 rounded-full hover:bg-red-700',
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}
